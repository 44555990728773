import { useAuthenticationContext } from 'contexts';
import { useCallback, useEffect } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ChangeAccountRequest,
  ChangePinnedAccountOptionalParams,
  CreateAccountOptionalParams,
  GetAccountOptionalParams,
  GetSubscriptionOptionalParams,
  ListAccountsFromUserOptionalParams,
  SupportEmailRequest,
  UpdateAccountRequest,
  UserAccountsResponse,
} from 'services/api/client/src';
import { HttpClient, msalInstance } from 'services/utils/security';
import { delay } from 'utils';
/**
 * Get the company account that matches the currently logged in user
 */
export const getAccount = async (params?: GetAccountOptionalParams) =>
  HttpClient.getAccount(params);

/**
 * Create company account for new sign up
 */
const createAccount = async (params?: CreateAccountOptionalParams) =>
  HttpClient.createAccount(params);

/**
 * Get account subscription
 */
const getAccountSubscription = async (params?: GetSubscriptionOptionalParams) =>
  HttpClient.getSubscription(params);

/**
 * update company account that matches the currently logged in user
 */
const updateAccount = async (body: UpdateAccountRequest) => HttpClient.updateAccount({ body });

/**
 * list all company accounts that matches the currently logged in user
 */
const listAccounts = async (options?: ListAccountsFromUserOptionalParams) =>
  HttpClient.listAccountsFromUser(options);

/**
 * pin company account that matches the currently logged in user
 */

const pinAccount = async (params: ChangePinnedAccountOptionalParams) =>
  HttpClient.changePinnedAccount(params);
/**
 * switch company account that matches the currently logged in user
 */

export const setActiveAccount = async (account: UserAccountsResponse) => {
  localStorage.setItem('activeAccount', JSON.stringify(account));
  return account;
};
export const removeActiveAccount = async (account: UserAccountsResponse) => {
  localStorage.removeItem('activeAccount');
  return account;
};

export const getActiveAccount = (): UserAccountsResponse => {
  const activeAccount = localStorage.getItem('activeAccount'); // maybe change this to getCurrentAccount?
  return activeAccount ? JSON.parse(activeAccount) : undefined;
};

const switchAccount = async (body: ChangeAccountRequest) => HttpClient.changeAccount({ body });

const sendSupportEmail = (options?: SupportEmailRequest): Promise<void> =>
  HttpClient.sendSupportEmail({
    body: options,
  });

/**
 * Queries
 */

export const useUpdateAccount = (queryClient: QueryClient) =>
  useMutation((body: UpdateAccountRequest) => updateAccount(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('account');
    },
  });
export const useCreateAccount = (queryClient: QueryClient) =>
  useMutation((body: CreateAccountOptionalParams) => createAccount(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('account');
    },
  });

export const useAccountSubscription = (params?: GetSubscriptionOptionalParams) =>
  useQuery(['subscription', params], () => getAccountSubscription(params), {
    retry: false,
  });

/* list accounts */
export const useListAccounts = (params?: ListAccountsFromUserOptionalParams) =>
  useQuery(['listAccounts', params], () => listAccounts(params), {
    refetchOnWindowFocus: false,
    retry: false,
  });

/* pin account */
export const usePinAccount = (queryClient: QueryClient) =>
  useMutation((body: ChangePinnedAccountOptionalParams) => pinAccount(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['listAccounts']);
    },
  });

/* switch account */
export const useSwitchAccount = (queryClient: QueryClient) =>
  useMutation(({ body }: { body: ChangeAccountRequest }) => switchAccount(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['listAccounts']);
      queryClient.invalidateQueries(['account']);
    },
  });

const useAccount = (params?: GetAccountOptionalParams) =>
  useQuery(['account', params], () => getAccount(params), {
    refetchOnWindowFocus: false,
    retry: false,
  });

export const useActiveAccount = () => useQuery(['activeAccount'], () => getActiveAccount());

export const useSetActiveAccount = (queryClient: QueryClient) =>
  useMutation((body: UserAccountsResponse) => setActiveAccount(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['activeAccount']);
    },
  });
export const useRemoveActiveAccount = (queryClient: QueryClient) =>
  useMutation((body: UserAccountsResponse) => removeActiveAccount(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['activeAccount']);
    },
  });

/* forcefully switch account */
export const useForceSwitchAccount = () => {
  const queryClient = useQueryClient();
  const switchAcc = useSwitchAccount(queryClient);
  const { currentUser } = useAuthenticationContext();
  const { data: activeAccount } = useActiveAccount();
  const { data: accounts } = useListAccounts();
  const { data: account } = useAccount();

  const checkActiveAccount = useCallback(async () => {
    if ((accounts?.length || 0) > 1 && activeAccount?.userId && account?.id && currentUser?.id) {
      const isAccountMatch = accounts?.find((item) => item.userId === activeAccount?.userId);
      if (isAccountMatch) {
        if (account?.id !== activeAccount?.userId) {
          await switchAcc.mutateAsync({
            body: {
              accountId: activeAccount.accountId,
              // userId: currentUser?.id || '',
            },
          });
          await msalInstance.acquireTokenSilent({
            scopes: ['openid', 'profile'],
            account: msalInstance.getAllAccounts()?.[0],
          });
          window.sessionStorage.clear();
          window.caches.keys().then((names) => {
            names.forEach((name) => {
              window.caches.delete(name);
            });
          });
          window.indexedDB.deleteDatabase('msal');

          window.sessionStorage.clear();
          window.caches.keys().then((names) => {
            names.forEach((name) => {
              window.caches.delete(name);
            });
          });
          window.indexedDB.deleteDatabase('msal');
          const scopes = ['openid', 'profile', 'email'];

          await delay(2000);

          await msalInstance.loginRedirect({
            // pick random scopes to force login
            scopes,
            extraQueryParameters: {
              isDesktopApp: 'true',
            },
          });
        }
      }
    }
  }, [activeAccount, accounts, account, currentUser, switchAcc]);

  useEffect(() => {
    checkActiveAccount();
  }, [checkActiveAccount]);
};

export const useAccountAsync = () =>
  useMutation((params?: GetAccountOptionalParams) => getAccount(params));

export const useSendSupportRequest = () =>
  useMutation((params?: SupportEmailRequest) => sendSupportEmail(params));

export const setNewShipInfoShown = async (flag: boolean) => {
  localStorage.setItem('newShipInfoShown', String(flag));
};
export const removeNewShipInfoShown = async () => {
  localStorage.removeItem('newShipInfoShown');
};

export const getNewShipInfoShown = (): Boolean => {
  const flag = localStorage.getItem('newShipInfoShown'); // maybe change this to getCurrentAccount?
  return flag ? Boolean(flag) : false;
};

export default useAccount;
