import { Col, ColProps, Empty, Modal, Row, RowProps, Space, Typography } from 'antd';
import { newShipInfoIcon } from 'assets';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getNewShipInfoShown, setNewShipInfoShown } from 'services';
import { supportEmailLink } from 'utils';
import ActivityInvite from './ActivityEvents';
import Map from './Map';
import ShipmentGraph from './ShipmentGraph';
import ShipmentInvite from './ShipmentInvite';
import styles from './index.module.less';

const colMapProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 16 };
const colShipInviteProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 8 };
const colhalfProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 12 };
const rowProps: RowProps = { gutter: [24, 24] };
const Dashboard: FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });

  const infoModal = useCallback(
    () =>
      Modal.info({
        title: 'We’ve Updated Shipments!',
        content: (
          <Empty
            image={newShipInfoIcon}
            description={
              <Space direction="vertical">
                <Typography.Paragraph className={styles.paragraph}>
                  We&apos;ve upgraded our shipment process to create a more powerful user experience
                  and streamline your workflow.
                </Typography.Paragraph>
                <Typography.Paragraph className={styles.paragraph}>
                  {/* Learn more&nbsp;
                  <Typography.Link href={supportEmailLink}>here</Typography.Link> */}
                  Please reach out to&nbsp;
                  <Typography.Link href={supportEmailLink}>support</Typography.Link>
                  &nbsp;if you have any questions.
                </Typography.Paragraph>
              </Space>
            }
          >
            <div />
          </Empty>
        ),
        okText: 'Done',
        cancelButtonProps: {
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onOk: () => setNewShipInfoShown(true),
        icon: null,
        width: 500,
      }),
    [],
  );
  useEffect(() => {
    if (!getNewShipInfoShown()) {
      Modal?.destroyAll();
      infoModal();
    }
  }, [infoModal]);

  return (
    <div className="dashboardcontent">
      <Row {...rowProps}>
        <Col {...colMapProps}>
          <Map />
        </Col>
        <Col {...colShipInviteProps}>
          <ShipmentInvite t={t} />
        </Col>
        <Col {...colhalfProps}>
          <ShipmentGraph t={t} />
        </Col>
        <Col {...colhalfProps}>
          <ActivityInvite t={t} />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(Dashboard);
