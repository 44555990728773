import { Col, Row, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { dateLocaleFormat } from 'utils';
import { InboundShipmentTableRowDataProps } from '../typings';
import styles from './index.module.less';

const InboundShipmentTableRowData: FC<InboundShipmentTableRowDataProps> = ({ record }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  return (
    <div>
      <Typography.Text strong>{`${record?.originCompanyName} `}</Typography.Text>

      <Row justify="space-between" gutter={[16, 16]}>
        <Col>
          <Typography.Text className={styles.tabledetails} type="secondary">{`${t(
            'number_of_products',
          )} ${record?.numberOfProducts || '-'}`}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text className={styles.tabledetails} type="secondary">{`${t('location')} ${
            record?.originLocationName || '-'
          }`}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text className={styles.tabledetails} type="secondary">{`${t(
            'ship_date_time',
          )} ${dateLocaleFormat(record?.eventDate || new Date()) || '-'}`}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text className={styles.tabledetails} type="secondary">{`${t(
            'purchase_order',
          )}  ${record?.purchaseOrderNumber || '-'}`}</Typography.Text>
        </Col>
      </Row>
    </div>
  );
};
export default InboundShipmentTableRowData;
