import type { ActionType, ListToolBarProps, ProColumns } from '@ant-design/pro-table';
import { TablePaginationConfig } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions } from 'components';
import GTable from 'components/GTable';
import { UseModalVisibilityReturnType } from 'hooks/useModalVisibility';
import { useEventsStore } from 'pages/Events';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  GetPaginatedInboundShipmentsOptionalParams,
  InboundShipmentResponse,
} from 'services/api/client/src';
import { useInboundShipments } from 'services/api/newShipments';
import { dateLocaleFormat } from 'utils';
import useShipmentStore from '../../Shipments/hooks';
import useNewShipmentActions from '../hooks/useNewShipmentActions';
import NoShipmentsFound from '../NoShipmentsFound';
import ShipmentToolbar from '../ShipmentToolbar';
import { InboundInternalShipmentProps } from '../typings';
import InboundShipmentTableRowData from './InboundShipmentTableRowData';
import ShipmentDetailsTable from './ShipmentDetailsTable';

type RowActionsType = {
  t?: TFunction<'pages', 'shipments'>;
};
const rowActionItems = ({ t }: RowActionsType): Array<ItemType> => [
  {
    key: 'preview_shipment',
    label: t?.('preview_shipment'),
  },
  {
    key: 'receive',
    label: t?.('receive'),
  },
  /*  {
    key: 'reject',
    label: t?.('reject'),
  }, */
];

type ColumnsType = {
  t?: TFunction<'pages', 'products'>;
  supportModal?: UseModalVisibilityReturnType;
  productNameSearchfilter?: ProColumns;
  onRowActionClick: (actionItemKey: string, record?: InboundShipmentResponse) => void;
};

const columns = ({
  t,
  productNameSearchfilter,
  onRowActionClick,
}: ColumnsType): Array<ProColumns<InboundShipmentResponse>> => [
  {
    dataIndex: 'shipmentId',
    hideInTable: true,
  },
  {
    title: t?.('col_product'),
    dataIndex: 'originCompanyName',
    hideInSetting: true,
    render: (text, record) => <InboundShipmentTableRowData record={record} />,
    ellipsis: true,
    ...productNameSearchfilter,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: 120,
    hideInSetting: true,
    fixed: 'right',
    render: (text, record) =>
      record?.shipmentId ? (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
            type: 'link',
          }}
          items={rowActionItems({ t })}
          onClick={onRowActionClick}
          actionPayload={record}
        />
      ) : undefined,
  },
];

const InboundInternalShipments: FC<InboundInternalShipmentProps> = ({
  selectedRowKeys,
  onRowSelectionChange,
  inboundShipmentType,
  showFilter,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'global_search.shipments_search_table' });
  const { setSelectedShipments } = useShipmentStore();
  const { setAdvancedSettings, setDataEntryMethod, setTemplate } = useEventsStore();
  const navigate = useNavigate();
  const actionRef = useRef<ActionType>();
  const [params, setParams] = useState<GetPaginatedInboundShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    sortBy: '-eventDate',
    status: ['Pending'],
    inboundShipmentType,
  });
  const { data, isLoading } = useInboundShipments(params);
  const { onShowErrorExternalShipmentDialog } = useNewShipmentActions();

  const expandedRowRender = (row: InboundShipmentResponse) => (
    <ShipmentDetailsTable shipmentId={row?.shipmentId} />
  );

  const onTableChange = (pagination: TablePaginationConfig, sorter: any) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };

  const onViewEvent = useCallback(
    (record?: InboundShipmentResponse) => {
      if (record?.shipmentStatus === 'Error') {
        onShowErrorExternalShipmentDialog();
      } else {
        const productsSearchParams = createSearchParams({
          shipmentId: record?.shipmentId || '',
          shipmentType: inboundShipmentType || '',
          tradePartnerUrn: record?.destinationCompanyUrn || '',
          locationUrn: record?.destinationLocationUrn || '',
          locationId: record?.destinationLocationId || '',
          eventDate: dateLocaleFormat(record?.eventDate) || '',
        });
        // sets signle shippment details in store
        setSelectedShipments([record as InboundShipmentResponse]);
        navigate({
          pathname: `/products/${record?.shipmentId}/events/receive`,
          search: record ? `?${productsSearchParams}` : undefined,
        });
      }
    },
    [inboundShipmentType, navigate, onShowErrorExternalShipmentDialog, setSelectedShipments],
  );

  const onPreviewShip = useCallback(
    (record?: InboundShipmentResponse) => {
      const previewSearchParams = createSearchParams({
        name: record?.originCompanyName || '',
        hideDocument: String(inboundShipmentType === 'Wholechain' || 'false'),
      });
      // sets signle shippment details in store
      setSelectedShipments([record as InboundShipmentResponse]);
      navigate({
        pathname: `./${record?.shipmentId}/eventDetails`,
        search: record ? `?${previewSearchParams}` : undefined,
      });
    },
    [inboundShipmentType, navigate, setSelectedShipments],
  );

  const onRowActionClick = useCallback(
    (actionItemKey: string, actionPayload?: InboundShipmentResponse) => {
      switch (actionItemKey) {
        case 'receive':
          onViewEvent(actionPayload);
          break;

        /*  case 'reject':
          break; */

        case 'preview_shipment':
          onPreviewShip(actionPayload);
          break;

        default:
          break;
      }
      setAdvancedSettings(false);
      setDataEntryMethod('manual');
      setTemplate(undefined);
    },
    [onPreviewShip, onViewEvent, setAdvancedSettings, setDataEntryMethod, setTemplate],
  );

  useEffect(() => {
    if (!showFilter) {
      setParams({
        pageNumber: 1,
        pageSize: 10,
        sortBy: '-eventDate',
        status: ['Pending'],
        inboundShipmentType,
      });
    }
  }, [inboundShipmentType, showFilter]);

  const toolBarRender: ListToolBarProps = useMemo(
    () =>
      showFilter
        ? {
            multipleLine: true,
            filter: (
              <ShipmentToolbar
                t={t}
                originLocationUrn={params.originLocationUrns}
                purchaseOrderNumber={params.purchaseOrderNumber}
                originTradePartnerUrn={params.originTradePartnerUrns}
                onLocationChange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    originLocationUrns: value,
                  })
                }
                onTradePartnerChange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    originTradePartnerUrns: value,
                  })
                }
                onPOchange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    purchaseOrderNumber: value,
                  })
                }
              />
            ),
          }
        : {},
    [showFilter, t, params],
  );
  return (
    <GTable<InboundShipmentResponse>
      actionRef={actionRef}
      showHeader={false}
      rowKey="shipmentId"
      columns={columns({ t, onRowActionClick })}
      columnsState={{
        persistenceKey: 'pages-products1',
      }}
      loading={isLoading}
      value={data?.results}
      enableRowSelection
      recordCreatorProps={false}
      pagination={{
        defaultPageSize: params.pageSize,
        current: params?.pageNumber,
        total: data?.totalItems,
        showTotal: (total) =>
          total <= 1
            ? t('search_table_pagi_shipment', { total })
            : t('search_table_pagi', { total }),
      }}
      rowSelection={{
        selectedRowKeys,
        onChange: onRowSelectionChange,
        columnWidth: 30,
        preserveSelectedRowKeys: true,
      }}
      onTableChange={onTableChange}
      scroll={{ x: 915 }}
      expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
      options={{
        setting: false,
        reload: false,
      }}
      toolbar={showFilter ? toolBarRender : undefined}
      emptyContainer={
        <NoShipmentsFound
          t={t}
          params={params}
          shipmentType={inboundShipmentType || ''}
          isInbound
        />
      }
    />
  );
};

export default React.memo(InboundInternalShipments);
