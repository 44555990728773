import { message, Modal, Space, Typography } from 'antd';
import { useModalVisibility } from 'hooks';
import { useGetLocationMutate } from 'hooks/useListLocation/useListLocation';
import pluralize from 'pluralize';
import { Key, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccount, useUpdateAccount } from 'services/api';
import {
  CreateNetworkInviteRequest,
  LocationResponse,
  UpdateAccountRequest,
} from 'services/api/client/src';
import {
  useDeleteLocations,
  useDeleteNetworkLocations,
  useGetLocationById,
  useUnarchiveLocationById,
} from 'services/api/useLocations';
import {
  useCreateNetwokInvite,
  useDeleteNetworkInvite,
  useDeleteNetworkTradePartners,
} from 'services/api/useTradePartners';
import errorHandler from 'utils/errorHandler';
import { LocationCols } from '../forms/AddEdit/LocationTable/typings';
import styles from '../index.module.less';
import { NetworkItem } from '../typings';
import useNetworkActions from './useNetworkActions';
import useSubscriptionAction from './useSubscriptionActions';

const useDeleteActions = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('pages', { keyPrefix: 'network' });
  const deleteLocation = useDeleteNetworkLocations(queryClient);
  const deleteCompany = useDeleteNetworkTradePartners(queryClient);
  const deleteInvite = useDeleteNetworkInvite(queryClient);
  // const resendInviteAPI = useResendNetworkInvite(queryClient);
  const deleteLocationAPI = useDeleteLocations(queryClient);
  const { getLocationAPICall } = useGetLocationMutate();
  const getLocationObj = useGetLocationById();
  const { onViewArchived } = useNetworkActions();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const upgradeModal = useModalVisibility(false);
  const { allowExternalLocation, allowInternalLocation } = useSubscriptionAction();
  const updateUser = useUpdateAccount(queryClient);
  const { data: accountData } = useAccount();
  const unarchiveLocation = useUnarchiveLocationById(queryClient);
  const [unArchiveLoading, setUnarchiveLoading] = useState<boolean>();
  const inviteNetworkUsers = useCreateNetwokInvite(queryClient);

  const deleteLastLocationWarning = async (locationName: string, ownerIsAccount: boolean) =>
    new Promise((resolve) => {
      Modal.warning({
        title: t('delete_location.last_loc_warning_title'),
        content: ownerIsAccount
          ? t('delete_location.last_my_loc_warning_content', { location: locationName })
          : t('delete_location.last_loc_warning_content', {
              location: locationName,
            }),
        centered: true,
        okCancel: true,
        width: 500,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
          className: styles.canceltext,
        },
        okText: t('delete_location.last_loc_warning_ok'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onCancel: () => resolve(false),
        onOk: () => resolve(true),
      });
    });

  const hasActiveInventoryWarning = async (locationName?: string) =>
    new Promise((resolve) => {
      Modal.warning({
        title: t('delete_location.has_active_inventory_title'),
        content: locationName
          ? t('delete_location.has_active_inventory_content', { location: locationName })
          : t('delete_location.has_active_multi_inventory_content'),
        centered: true,
        okCancel: false,
        width: 500,
        okText: t('delete_location.done'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onOk: () => resolve(true),
      });
    });

  const showSuccessMessage = (location: string, isLast?: boolean) => {
    const Text: ReactNode = (
      <Space>
        <Typography.Text>
          {t('delete_location.delete_location_success', { location })}
        </Typography.Text>
        <Typography.Link
          onClick={(e) => {
            e.preventDefault();
            onViewArchived();
          }}
        >
          {`  ${t('delete_location.view_archived')}`}
        </Typography.Link>
      </Space>
    );
    message.success({
      content: Text,
    });
    if (isLast) {
      if (locationPath?.pathname?.includes('editLocationId')) {
        navigate('../../../');
      } else {
        navigate('../../');
      }
    }
  };

  const showBulkSuccessMessage = (count: number) => {
    const Text: ReactNode = (
      <Space>
        <Typography.Text>
          {t('delete_location.delete_bulk_success', {
            loc_count: count,
            locations: pluralize(t('location'), count),
          })}
        </Typography.Text>
        <Typography.Link
          onClick={(e) => {
            e.preventDefault();
            onViewArchived();
          }}
        >
          {`  ${t('delete_location.view_archived')}`}
        </Typography.Link>
      </Space>
    );
    message.success({
      content: Text,
    });
  };

  const archiveLocationFromNetwork = async (
    locId: string,
    locationName: string,
    ownerIsAccount: boolean,
    compLocations: any,
    hideMessage?: boolean,
  ) => {
    try {
      if (compLocations?.length === 1) {
        const consent = await deleteLastLocationWarning(locationName, ownerIsAccount);
        if (consent) {
          try {
            await deleteLocation.mutateAsync({ ids: [locId] });
            if (!hideMessage) {
              showSuccessMessage(locationName, true);
            }
            return true;
          } catch (error) {
            if (errorHandler(error)) {
              message.error(errorHandler(error));
            }
          }
        }
      } else {
        await deleteLocation.mutateAsync({ ids: [locId] });
        if (!hideMessage) {
          showSuccessMessage(locationName);
        }
        return true;
      }
      return false;
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
    return false;
  };

  const cancelInvite = async (id: string, companyName?: string) => {
    try {
      await deleteInvite.mutateAsync({ id });
      message.success(t('invite.delete_success_invite', { company: companyName }));
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  const resendInvite = async (actionPayload?: NetworkItem) => {
    try {
      const compReq: Array<CreateNetworkInviteRequest> = [
        {
          wholechainId: actionPayload?.wholechainId,
        },
      ];
      await inviteNetworkUsers.mutateAsync({
        options: compReq,
      });
      message.success(t('invite.resent_success_invite', { company: actionPayload?.company }));
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  const archieveLocation = async (locObj: LocationResponse, hideMessage?: boolean) => {
    const compLocations = await getLocationAPICall({
      partnerIds: [locObj?.tradePartnerId || ''],
    });
    const status = await archiveLocationFromNetwork(
      locObj?.id || '',
      locObj?.name || '',
      locObj?.networkStatus === 'Self' || false,
      compLocations || [],
      hideMessage,
    );
    return status;
  };

  const archiveLocationById = async (loc: Key, hideMessage?: boolean) => {
    try {
      const locObj = await getLocationObj.mutateAsync({ id: String(loc) });
      if (locObj?.hasActiveInventory || locObj?.sharedWith?.length) {
        if (hideMessage) {
          return locObj?.name;
        }
      }
      const status = await archieveLocation(locObj, hideMessage);
      return status;
    } catch (error) {
      return false;
    }
  };

  const archiveConsent = useCallback(
    (locationName?: string) =>
      new Promise((resolve) => {
        Modal.warning({
          title: locationName
            ? t('archive_location_consent.title')
            : t('archive_location_consent.multi_title'),
          content: locationName
            ? t('archive_location_consent.content', { location: locationName })
            : t('archive_location_consent.multi_content'),
          centered: true,
          okCancel: true,
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
          },
          okText: t('archive_location_consent.yes'),
          cancelText: t('archive_location_consent.no'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        });
      }),
    [t],
  );

  const archiveSingleLocation = async (loc: Key) => {
    try {
      const locObj = await getLocationObj.mutateAsync({ id: String(loc) });
      if (locObj?.hasActiveInventory || locObj?.sharedWith?.length) {
        await hasActiveInventoryWarning(locObj?.name || '');
        return false;
      }
      const consent = await archiveConsent(locObj?.name);
      if (!consent) {
        return false;
      }
      const status = await archieveLocation(locObj);
      return status;
    } catch (error) {
      return false;
    }
  };

  const archiveMultileLocations = async (selectedRowKeys: Array<Key>) => {
    const consent = await archiveConsent();
    if (!consent) {
      return true;
    }
    const loadingMessage = message.loading(t('delete_location.bulk_archive_loading'), 0);
    let total = 0;
    let showActiveInventoryPopup: string | boolean = false;
    const bulkPromise = selectedRowKeys.reduce(
      (p, id) =>
        p.then(async () => {
          // hide success message and navigation
          const status = await archiveLocationById(id, true);
          if (status) {
            if (status === true) {
              total += 1;
            } else {
              showActiveInventoryPopup = status || true;
            }
          }
        }),
      Promise.resolve(),
    );
    await bulkPromise;
    loadingMessage();
    if (showActiveInventoryPopup) {
      await hasActiveInventoryWarning(
        selectedRowKeys?.length === 1 ? showActiveInventoryPopup : undefined,
      );
    }
    if (total) {
      showBulkSuccessMessage(total);
      navigate('../../');
    }
    return true;
  };

  const updateVesselSetting = async () =>
    new Promise((resolve) => {
      Modal.warning({
        title: t('unarchive_vessel_warning.title'),
        content: t('unarchive_vessel_warning.content'),
        centered: true,
        okCancel: true,
        width: 500,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
          className: styles.canceltext,
        },
        okText: t('delete_location.last_loc_warning_ok'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onCancel: () => resolve(false),
        onOk: () => resolve(true),
      });
    });

  const unArchiveLocationById = async (loc: Key) => {
    try {
      await unarchiveLocation.mutateAsync({
        ids: [String(loc) || ''],
      });
      return true;
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
      return false;
    }
  };

  const onViewUnarchived = useCallback(() => {
    navigate({
      pathname: `/network`,
    });
  }, [navigate]);

  const onUnarchiveMultipleLocations = async (selectedRowKeys: Array<Key>) => {
    let loadingMessage = message.loading(
      selectedRowKeys?.length === 1 ? t('unarchive_single_loading') : t('unarchive_loading'),
      0,
    );
    let total = 0;
    let allowVesselUnarchive = accountData?.vesselEnabled;
    let shownConsentPopup = false;
    let name = '';
    let showUpradePopup = false;
    setUnarchiveLoading(true);
    const bulkPromise = selectedRowKeys.reduce(
      (p, id) =>
        p.then(async () => {
          const locObj = await getLocationObj.mutateAsync({
            id: String(id),
          });
          const allowUnArchive =
            locObj?.networkStatus === 'Self' ? allowInternalLocation : allowExternalLocation;
          if (allowUnArchive) {
            if (selectedRowKeys.length === 1) {
              name = locObj.name || '';
            }
            if (!shownConsentPopup && !accountData?.vesselEnabled && locObj?.vessel) {
              loadingMessage();
              const consent = await updateVesselSetting();
              loadingMessage = message.loading(
                selectedRowKeys?.length === 1
                  ? t('unarchive_single_loading')
                  : t('unarchive_loading'),
                0,
              );
              allowVesselUnarchive = Boolean(consent);
              shownConsentPopup = true;
              if (consent) {
                try {
                  const reqData: UpdateAccountRequest = {
                    vesselEnabled: true,
                  };
                  await updateUser.mutateAsync(reqData);
                  const status = await unArchiveLocationById(id);
                  if (status) {
                    total += 1;
                  }
                } catch (error) {
                  if (errorHandler(error)) {
                    message.error(errorHandler(error));
                  }
                }
              }
            } else if (locObj?.vessel) {
              if (allowVesselUnarchive) {
                const status = await unArchiveLocationById(id);
                if (status) {
                  total += 1;
                }
              }
            } else {
              const status = await unArchiveLocationById(id);
              if (status) {
                total += 1;
              }
            }
          } else {
            showUpradePopup = true;
          }
        }),

      Promise.resolve(),
    );
    await bulkPromise;
    loadingMessage();
    setUnarchiveLoading(false);
    if (total) {
      message.success(
        <Space>
          {total === 1
            ? t('unarchive_success', { name })
            : t('unarchive_success_many', { count: total || 0 })}
          <Typography.Link
            onClick={(e) => {
              e.preventDefault();
              onViewUnarchived();
            }}
          >
            {t('view_networks')}
          </Typography.Link>
        </Space>,
      );
    }
    if (showUpradePopup) {
      upgradeModal?.show();
    }
    return true;
  };

  const deleteConfimation = useCallback(
    async (locationName: string) =>
      new Promise((resolve) => {
        Modal.warning({
          title: t('consent.delete_warning', { location: locationName }),
          content: t('consent.delete_content', { location: locationName }),
          centered: true,
          okCancel: true,
          width: 500,
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
            className: styles.canceltext,
          },
          okText: t('consent.delete_location'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        });
      }),
    [t],
  );

  const deleteErrorModal = useCallback(
    (locationName: string) =>
      Modal.error({
        title: t('delete_error.delete_warning', { location: locationName }),
        content: t('delete_error.delete_content', { location: locationName }),
        okText: t('delete_error.done'),
        cancelButtonProps: {
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      }),
    [t],
  );

  const deleteLastLocation = useCallback(
    async (locationName: string, companyName: string) =>
      new Promise((resolve) => {
        Modal.warning({
          title: t('delete_last_location.delete_warning', { location: locationName }),
          content: t('delete_last_location.delete_content', {
            location: locationName,
            company: companyName,
          }),
          centered: true,
          okCancel: true,
          width: 500,
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
            className: styles.canceltext,
          },
          okText: t('delete_last_location.delete_location'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        });
      }),
    [t],
  );

  const onDeleteLocation = useCallback(
    async (
      record?: LocationCols,
      companyName?: string,
      isLastLocation?: boolean,
      closeModal?: () => void,
    ) => {
      const consent = isLastLocation
        ? await deleteLastLocation(record?.locationName || '', companyName || '')
        : await deleteConfimation(record?.locationName || '');
      if (consent) {
        try {
          await deleteLocationAPI.mutateAsync({
            ids: [record?.id || ''],
          });

          message.success(
            t('delete_location.delete_single_location_success', {
              location: record?.locationName || '',
            }),
          );
          if (isLastLocation) {
            closeModal?.();
          }
        } catch (err) {
          deleteErrorModal(record?.locationName || '');
          // message.error(errorHandler(err));
        }
      }
    },
    [deleteConfimation, deleteErrorModal, deleteLastLocation, deleteLocationAPI, t],
  );

  const deleteCompanyConfimation = useCallback(
    async (compName: string) =>
      new Promise((resolve) => {
        Modal.warning({
          title: t('consent.delete_comp_warning', { company: compName }),
          content: t('consent.delete_comp_content', { company: compName }),
          centered: true,
          okCancel: true,
          width: 500,
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
            className: styles.canceltext,
          },
          okText: t('consent.delete_comp_button'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        });
      }),
    [t],
  );

  const deleteCompanyErrorModal = useCallback(
    (compName: string) =>
      Modal.error({
        title: t('delete_comp_error.delete_warning', { company: compName }),
        content: t('delete_comp_error.delete_content'),
        okText: t('delete_comp_error.done'),
        cancelButtonProps: {
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      }),
    [t],
  );

  const deleteCompanyById = useCallback(
    async (compId: string, compName: string) => {
      try {
        const consent = await deleteCompanyConfimation(compName);
        if (consent) {
          await deleteCompany.mutateAsync({ id: compId });
          message.success(t('delete_location.delete_company_success'));
          return true;
        }
      } catch (error) {
        deleteCompanyErrorModal(compName);
        // message.error(errorHandler(error));
      }
      return false;
    },
    [deleteCompany, deleteCompanyConfimation, deleteCompanyErrorModal, t],
  );

  return {
    archiveSingleLocation,
    cancelInvite,
    resendInvite,
    deleteCompanyById,
    archiveMultileLocations,
    onUnarchiveMultipleLocations,
    upgradeModal,
    loading:
      deleteLocation.isLoading ||
      deleteCompany.isLoading ||
      deleteInvite.isLoading ||
      inviteNetworkUsers.isLoading,
    onDeleteLocation,
    deleteLastLocationWarning,
    unArchiveLoading,
  };
};

export default useDeleteActions;
