import { Col, Row } from 'antd';
import { ShareDocument } from 'components';
import useListLocation from 'hooks/useListLocation';
import useModalVisibility from 'hooks/useModalVisibility';
import { DocumentItem } from 'pages/Documents/typings';
import { SelectedProps } from 'pages/Network/forms/ShareDocument/typings';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetCompanyNetworkDetails, useTradePartnerById } from 'services/api/useTradePartners';
import { getAccountType } from 'utils/network';
import {
  LocationHeader,
  LocationInfoBar,
  ShipmentContainer,
  SingleMapLocation,
  SkeletonLoader,
  TableContainer,
} from './components';
import { AttributeCols } from './components/TableContainer/AttributeTable/typings';
import { DeleteLocations, ShareLocation } from './forms';
import useLocationStore from './hook/useLocationStore';
import styles from './index.module.less';
import { NetworkItem } from './typings';

const colProps1 = { xs: 24, sm: 24, md: 16, lg: 16 };
const colProps2 = { xs: 24, sm: 24, md: 8, lg: 8 };

const ViewLocation: FC = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const shareLocModal = useModalVisibility(false);
  const shareDocModal = useModalVisibility(false);
  const deleteLocModal = useModalVisibility(false);
  const [locationToShare, setLocationToShare] = useState<NetworkItem>();
  const [documentToShare, setDocumentToShare] = useState<SelectedProps>();
  const [isLocationView, setLocationView] = useState<boolean>(true);
  const { data: companyData } = useTradePartnerById(String(companyId));
  const { data: dataLocations, isLoading: isLocationsLoading } = useListLocation({
    partnerIds: [String(companyId)],
    pageSize: 9999,
  });
  const companyLocations = useMemo(() => dataLocations || [], [dataLocations]);

  const { data: summaryInfo = {}, isLoading: isSummaryLoading } = useGetCompanyNetworkDetails(
    String(companyId),
  );
  const setLocationData = useLocationStore((state) => state.setLocationData);

  const isLoading = useMemo(
    () => isLocationView || isLocationsLoading || isSummaryLoading,
    [isLocationView, isLocationsLoading, isSummaryLoading],
  );

  const locProps: NetworkItem = useMemo(
    () =>
      ({
        id: '',
        companyId,
        company: companyData?.name || '',
        wcId: companyData?.wholechainId || '',
        urn: companyData?.urn,
        isLocationView: false,
        connectionType: summaryInfo?.connectionType,
        shipmentsReceived: summaryInfo?.shipmentsReceived,
        shipmentsSent: summaryInfo?.shipmentsSent,
        accountType: getAccountType(
          companyData?.isOwnerAccount,
          companyData?.hasWholechainAccount,
          companyData?.hasWholechainAccount && companyData?.type === 'NonWholechain',
        ),
      } || {}),
    [companyId, companyData, summaryInfo],
  );

  useEffect(() => {
    if (!isLocationsLoading && companyLocations.length === 1) {
      navigate(companyLocations[0]?.id || '');
    }
    window.scrollTo(0, 0);
    setLocationData(locProps);
    setLocationView(false);
  }, [setLocationData, locProps, isLocationsLoading, companyLocations, navigate]);

  return (
    <>
      {shareLocModal.visible && (
        <ShareLocation
          modal={shareLocModal}
          locationToShare={locationToShare}
          clearLocationToShare={() => setLocationToShare(undefined)}
        />
      )}
      {shareDocModal.visible && (
        <ShareDocument
          modal={shareDocModal}
          documentToShare={documentToShare}
          setDocumentToShare={() => setDocumentToShare(undefined)}
        />
      )}
      {deleteLocModal.visible && <DeleteLocations modal={deleteLocModal} />}
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <LocationHeader
            shareDocModal={shareDocModal}
            companyLocations={companyLocations}
            shareLocModal={shareLocModal}
            deleteLocModal={deleteLocModal}
            isLoading={isLoading}
          />
          <LocationInfoBar summaryInfo={summaryInfo} isLoading={isLoading} />
        </>
      )}

      <Row gutter={[20, 20]}>
        <Col {...colProps1}>
          <ShipmentContainer />
        </Col>
        <Col {...colProps2}>
          <SingleMapLocation companyLocations={companyLocations} />
        </Col>
      </Row>
      <div className={styles.container}>
        <TableContainer
          companyLocations={companyLocations}
          isMyLocations={companyLocations[0]?.networkStatus === 'Self' || false}
          onEditAttribute={(record: AttributeCols) => {
            if (record) {
              if (isLocationView) {
                navigate('editLocationId?tab=attribute');
              } else {
                navigate('editLocation?tab=attribute');
              }
            }
          }}
          onShareDocument={(record: DocumentItem) => {
            if (record) {
              setDocumentToShare({
                value: record?.id,
                label: record?.title,
              });
              shareDocModal.show();
            }
          }}
          onShareLocation={(record: NetworkItem) => {
            if (record) {
              setLocationToShare(record);
              shareLocModal.show();
            }
          }}
        />
      </div>
      <Outlet />
    </>
  );
};

export default React.memo(ViewLocation);
