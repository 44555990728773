import { create } from 'zustand';
import { LocationItem } from '../typings';
import { SelectTabStore } from './typings';

const useLocationStore = create<SelectTabStore>((set) => ({
  id: '',
  name: '',
  wcId: '',
  company: '',
  locationAddress: '',
  lat: 0,
  lng: 0,
  companyId: '',
  contact: {},
  accountType: '',
  isLocationView: false,
  totalEvents: 0,
  shipmentsReceived: 0,
  shipmentsSent: 0,
  totalShipments: 0,
  urn: '',
  connectionType: 'OTHER',
  setLocationData: (locationData: LocationItem) =>
    set(() => ({
      ...locationData,
    })),
}));

export default useLocationStore;
