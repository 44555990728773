import { TablePaginationConfig } from 'antd';
import { GTableDateType } from 'components/GTable/contexts/typings';
import { useTableSearchFilter } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTradePartners } from 'services/api';
import { GetPaginatedShipmentsOptionalParams } from 'services/api/client/src';
import { useShipments } from 'services/api/shipments';
import { camelizeFirstChar, downloadJsonToCSV, mapShipInvItem } from 'utils';
import { Shipment } from '../typings';

export type FilterType = {
  text: string;
  value: string;
};
const useShipFilters = (initParams?: GetPaginatedShipmentsOptionalParams) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  const [params, setParams] = useState<GetPaginatedShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: initParams?.pageSize || 20,
    ...initParams,
  });
  const { data, isLoading, refetch } = useShipments(params);
  const coreStatus: Array<FilterType> = [
    {
      text: t('pending'),
      value: 'Pending',
    },
    {
      text: t('received'),
      value: 'Received',
    },
    {
      text: t('rejected'),
      value: 'Rejected',
    },
    {
      text: t('shipped'),
      value: 'Shipped',
    },
  ];
  /* tradepartners */
  const { data: dataTradePartners } = useTradePartners();
  const coreTradePartners = useMemo(
    () =>
      dataTradePartners?.data?.map((option) => ({
        text: option?.name || '',
        value: option?.urn || '',
      })) || [],
    [dataTradePartners],
  );

  const totalItems = useMemo(() => data?.totalItems || 0, [data]);
  const netItemCount = useMemo(() => data?.totalItems || 0, [data]);
  const records: Array<Shipment> = useMemo(() => data?.results?.map(mapShipInvItem) || [], [data]);

  const onSearch = useCallback(
    (text: string, key: string) => {
      setParams({
        ...params,
        pageNumber: 1,
        [key]: text,
      });
    },
    [setParams, params],
  );
  const { filters: productSearchfilter } = useTableSearchFilter({
    title: t('tbl.filter_product_name'),
    onSearch: (text) => onSearch(text, 'productName'),
  });
  const { filters: primaryIdSearchfilter } = useTableSearchFilter({
    title: t('tbl.filter_primary_id'),
    onSearch: (text) => onSearch(text, 'primaryId'),
  });
  const { filters: ticketIdSearchfilter } = useTableSearchFilter({
    title: t('tbl.filter_ticket_id'),
    onSearch: (text) => onSearch(text, 'ticketId'),
  });
  const { filters: logisticIdSearchfilter } = useTableSearchFilter({
    title: t('tbl.filter_logistic_id'),
  });
  const { filters: purchaseOrderSearchfilter } = useTableSearchFilter({
    title: t('tbl.filter_purchase_order'),
    onSearch: (text) => onSearch(text, 'purchaseOrderNumber'),
  });
  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      setParams({
        ...params,
        pageNumber: page,
        pageSize,
      });
    },
    [params],
  );
  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, Array<string> | null>,
      sorter: any,
    ) => {
      const apiFilters: GetPaginatedShipmentsOptionalParams = {
        ...(filters?.status?.length && { status: filters?.status }),
        ...(params?.direction === 'Inbound' && {
          shippedFromTradePartnerUrn: filters?.senderName?.length
            ? filters?.senderName
            : initParams?.shippedFromTradePartnerUrn || undefined,
        }),
        ...(params?.direction === 'Outbound' && {
          shippedToTradePartnerUrn: filters?.receiverName?.length
            ? filters?.receiverName
            : initParams?.shippedToTradePartnerUrn || undefined,
        }),
      };
      if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
        let sortValue;
        if (sorter?.field) {
          sortValue = `${sorter.order === 'ascend' ? '+' : '-'}${camelizeFirstChar(sorter.field)}`;
        } else if (params?.sortBy) {
          sortValue = params?.sortBy;
        }
        setParams({
          ...params,
          ...apiFilters,
          sortBy: sortValue,
          pageNumber: 1,
        });
      } else {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: pagination?.current,
          pageSize: pagination?.pageSize,
        });
      }
    },
    [initParams?.shippedFromTradePartnerUrn, initParams?.shippedToTradePartnerUrn, params],
  );

  const onDownloadCSVMultiple = useCallback(
    (rows?: GTableDateType[]) => {
      if (rows?.length) {
        downloadJsonToCSV(rows);
      } else {
        downloadJsonToCSV(records || []);
      }
    },
    [records],
  );
  const onDownloadCSV = useCallback((actionPayload?: Shipment) => {
    if (actionPayload) {
      downloadJsonToCSV([actionPayload]);
    }
  }, []);
  return {
    records,
    totalItems,
    netItemCount,
    isLoading,
    onTableChange,
    onPageChange,
    params,
    setParams,
    coreTradePartners,
    coreStatus,
    onDownloadCSVMultiple,
    onDownloadCSV,
    refetch,
    filters: [
      productSearchfilter,
      primaryIdSearchfilter,
      ticketIdSearchfilter,
      purchaseOrderSearchfilter,
      logisticIdSearchfilter,
    ],
  };
};
export default useShipFilters;
