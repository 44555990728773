import { Col, ColProps, Input, Row, RowProps } from 'antd';
import { LocationSelect, TradePartnerSelect } from 'components';
import { FC } from 'react';
import styles from './index.module.less';
import { ShipmentToolbarProps } from './typings';

const col1Props: ColProps = { xs: 24, sm: 24, md: 8, lg: 8 };
const rowProps: RowProps = { gutter: [16, 16] };
const ShipmentToolbar: FC<ShipmentToolbarProps> = ({
  t,
  originLocationUrn,
  purchaseOrderNumber,
  originTradePartnerUrn,
  onLocationChange,
  onTradePartnerChange,
  onPOchange,
}) => (
  <Row className={styles.rowselectwidth} {...rowProps}>
    <Col {...col1Props}>
      <TradePartnerSelect
        className={styles.rowselectwidth}
        placeholder={t('col.originTradePartnerUrn')}
        mode="multiple"
        assignUrnAsValue
        value={originTradePartnerUrn}
        onChange={(value: any) => onTradePartnerChange(value)}
        showArrow
      />
    </Col>
    <Col {...col1Props}>
      <LocationSelect
        className={styles.rowselectwidth}
        value={originLocationUrn}
        onChange={(updatedValue) => onLocationChange(updatedValue)}
        placeholder={t('col.originLocationUrn')}
        mode="multiple"
        showSearch
        assignUrnAsValue
        showArrow
      />
    </Col>
    <Col {...col1Props}>
      <Input
        value={purchaseOrderNumber}
        onChange={(updatedValue) => onPOchange(updatedValue.target.value)}
        name="purchaseOrderNumber"
        placeholder={t('col.purchaseOrderNumber')}
      />
    </Col>
  </Row>
);
export default ShipmentToolbar;
