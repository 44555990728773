import * as signalR from '@microsoft/signalr';
import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  AggregateRequest,
  AggregateResponse,
  CommissionRequest,
  CommissionResponse,
  DecommissionRequest,
  DecommissionResponse,
  DisaggregateRequest,
  DisaggregateResponse,
  ListEventDetailsOptionalParams,
  ListOrphansOptionalParams,
  ListOrphansSuggestionsOptionalParams,
  MultiCommissionRequest,
  MultiObserveRequest,
  MultiReceiveRequest,
  MultiShipRequest,
  ObserveRequest,
  ObserveResponse,
  ReceiveRequest,
  ReceiveResponse,
  ResolveOrphanOptionalParams,
  ShipRequest,
  ShipResponse,
  TransformRequest,
} from 'services/api/client/src';
import { HttpClient, TokenCredential } from 'services/utils/security';
import { fetchRequest } from 'services/utils/security/HttpClient';
import { getVariableFromEnv } from 'utils';

/* commission */
export const createCommissionEvent = async (
  body: MultiCommissionRequest,
): Promise<CommissionResponse> => HttpClient.commission({ body });
export const createCommissionEvents = async (
  values: Array<CommissionRequest>,
): Promise<CommissionResponse> => HttpClient.commission({ body: { commissions: values } });
export const useCreateCommissionEvent = (queryClient: QueryClient) =>
  useMutation((data: MultiCommissionRequest) => createCommissionEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });
export const useCreateCommissionEvents = (queryClient: QueryClient) =>
  useMutation((data: Array<CommissionRequest>) => createCommissionEvents(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

/* agggregate */
export const createAggregateEvent = async (body: AggregateRequest): Promise<AggregateResponse> =>
  HttpClient.aggregate({ body });

export const useCreateAggregateEvent = (queryClient: QueryClient) =>
  useMutation((data: AggregateRequest) => createAggregateEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

/* ship */
export const createShipEvent = async (body: MultiShipRequest) =>
  fetchRequest({
    path: '/api/Events/Ship',
    method: 'POST',
    body,
  });
export const createMultiShipEvents = async (values: Array<ShipRequest>): Promise<ShipResponse> =>
  HttpClient.ship({ body: { ships: values } });
export const useCreateShipEvent = (queryClient: QueryClient) =>
  useMutation((data: MultiShipRequest) => createShipEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      // update shipment module
      queryClient.invalidateQueries('ListInboundShipments');
      queryClient.invalidateQueries('ListInboundOtherShipments');
      queryClient.invalidateQueries('OutboundShipments');
      queryClient.invalidateQueries('ListOutboundOtherShipments');
      queryClient.invalidateQueries('CountInboundShipments');
      queryClient.invalidateQueries('CountOutboundShipments');
      // queryClient.invalidateQueries('shipments');
      // queryClient.invalidateQueries(['infiniteShipments']);
      queryClient.invalidateQueries(['shipments_summary']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });
export const useMultiShipEvent = (queryClient: QueryClient) =>
  useMutation((data: Array<ShipRequest>) => createMultiShipEvents(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      // update shipment module
      queryClient.invalidateQueries('ListInboundShipments');
      queryClient.invalidateQueries('ListInboundOtherShipments');
      queryClient.invalidateQueries('OutboundShipments');
      queryClient.invalidateQueries('ListOutboundOtherShipments');
      queryClient.invalidateQueries('CountInboundShipments');
      queryClient.invalidateQueries('CountOutboundShipments');
      // queryClient.invalidateQueries('shipments');
      // queryClient.invalidateQueries(['infiniteShipments']);
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.refetchQueries(['shipments_summary']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

/* disaggregate event */
export const createDisaggregateEvent = async (
  body: DisaggregateRequest,
): Promise<DisaggregateResponse> => HttpClient.disaggregate({ body });

export const useCreateDisaggregateEvent = (queryClient: QueryClient) =>
  useMutation((data: DisaggregateRequest) => createDisaggregateEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

/* observe */
export const createObserveEvent = async (body: MultiObserveRequest): Promise<ObserveResponse> =>
  HttpClient.observe({ body });
export const createObserveEvents = async (
  values: Array<ObserveRequest>,
): Promise<ObserveResponse> => HttpClient.observe({ body: { observations: values } });

export const useCreateObserveEvent = (queryClient: QueryClient) =>
  useMutation((data: MultiObserveRequest) => createObserveEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });
export const useCreateObserveEvents = (queryClient: QueryClient) =>
  useMutation((data: Array<ObserveRequest>) => createObserveEvents(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

/* decommission */
export const createDecommissionEvent = async (
  body: DecommissionRequest,
): Promise<DecommissionResponse> => HttpClient.decommission({ body });

export const createMultiDecommissionEvents = async (
  values: Array<DecommissionRequest>,
): Promise<any> => {
  const promises = values.map(createDecommissionEvent);
  return Promise.all(promises);
};

export const useCreateDecommissionEvent = (queryClient: QueryClient) =>
  useMutation((data: DecommissionRequest) => createDecommissionEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

export const useMultiDecommissionEvent = (queryClient: QueryClient) =>
  useMutation((data: Array<DecommissionRequest>) => createMultiDecommissionEvents(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('shipments');
      queryClient.invalidateQueries(['infiniteShipments']);
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

/* transform */
export const createTransformEvent = async (body: TransformRequest) =>
  HttpClient.transform({ body });

export const useCreateTransformEvent = (queryClient: QueryClient) =>
  useMutation((data: TransformRequest) => createTransformEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

/* receive */
export const createReceiveEvent = async (body: MultiReceiveRequest) => HttpClient.receive({ body });
export const createReceiveEvents = async (
  values: Array<ReceiveRequest>,
): Promise<ReceiveResponse> => HttpClient.receive({ body: { receives: values } });

export const useCreateReceiveEvent = (queryClient: QueryClient) =>
  useMutation((data: MultiReceiveRequest) => createReceiveEvent(data), {
    onSuccess: () => {
      // update shipment module
      queryClient.invalidateQueries('ListInboundShipments');
      queryClient.invalidateQueries('ListInboundOtherShipments');
      queryClient.invalidateQueries('OutboundShipments');
      queryClient.invalidateQueries('ListOutboundOtherShipments');
      queryClient.invalidateQueries('CountInboundShipments');
      queryClient.invalidateQueries('CountOutboundShipments');

      // queryClient.invalidateQueries('shipments');
      // queryClient.invalidateQueries(['infiniteShipments']);
      // queryClient.invalidateQueries('externalShipments');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('product');
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries(['subProducts']);
      queryClient.invalidateQueries(['shipments_summary']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });
export const useCreateReceiveEvents = (queryClient: QueryClient) =>
  useMutation((data: Array<ReceiveRequest>) => createReceiveEvents(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('shipments');
      queryClient.invalidateQueries('externalShipments');
      queryClient.invalidateQueries(['infiniteShipments']);
      queryClient.invalidateQueries(['shipments_summary']);
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries(['subProducts']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

/* remove event */
export const removeEvent = async (eventId: string) => HttpClient.deleteEvent(eventId);

export const useRemoveEvent = (queryClient: QueryClient) =>
  useMutation((data: string) => removeEvent(data), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('shipments');
      queryClient.invalidateQueries(['infiniteShipments']);
      queryClient.invalidateQueries(['shipments_summary']);
      queryClient.invalidateQueries('poSearchResults');
    },
    retry: false,
  });

const host = getVariableFromEnv('API_HOST');
const credential = new TokenCredential();

export const getCsvUploadConnection = async () => {
  const tokenRes = await credential.getToken();
  const accessToken = tokenRes?.token || '';
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${host}/SignalR/csvProcessing`, {
      accessTokenFactory: () => accessToken,
      withCredentials: false,
    })
    .build();
  return connection;
};
export const getEvents = (params?: ListEventDetailsOptionalParams) =>
  HttpClient.listEventDetails(params);

export const useEvents = (params?: ListEventDetailsOptionalParams) =>
  useQuery(['events', params], () => getEvents(params), {
    retry: false,
  });

export const getOrphanEvents = (options?: ListOrphansOptionalParams) =>
  HttpClient.listOrphans(options);

// params: GetAllFirstEventsOfOrphansSupplyChainsOptionalParams
export const useOrphanEvents = (params?: ListOrphansOptionalParams) =>
  useQuery(['orphanEvents', params], () => getOrphanEvents(params), {
    retry: false,
  });

export const getOrphanSuggestions = (options?: ListOrphansSuggestionsOptionalParams) =>
  HttpClient.listOrphansSuggestions(options);

// params: ListOrphanSuggestionsOptionalParams
export const useOrphanSuggestions = (params?: ListOrphansSuggestionsOptionalParams) =>
  useQuery(['orphanSuggestions', params], () => getOrphanSuggestions(params), {
    staleTime: 0,
  });

export const resolveOrphanEvent = async (
  orphanInstanceId: string,
  instanceId: string,
  options?: ResolveOrphanOptionalParams,
) => HttpClient.resolveOrphan(orphanInstanceId, instanceId, options);

export const useResolveOrphanEvent = (queryClient: QueryClient) =>
  useMutation(
    ({
      orphanInstanceId,
      instanceId,
      options,
    }: {
      orphanInstanceId: string;
      instanceId: string;
      options?: ResolveOrphanOptionalParams;
    }) => resolveOrphanEvent(orphanInstanceId, instanceId, options),
    {
      onSuccess: () => {
        queryClient?.invalidateQueries('orphanEvents');
      },
    },
  );
