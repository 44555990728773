import { Tag, Tooltip } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SharedTagProps } from '../typings';

const SharedTag: FC<SharedTagProps> = ({ sharedWith, onClick }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents.shared_tag' });
  return sharedWith?.length ? (
    <Tooltip
      title={
        <div>
          {sharedWith?.map(({ accountName }) => (
            <div>{accountName}</div>
          ))}
        </div>
      }
    >
      <Tag color="processing" onClick={onClick}>
        {t('with')}{' '}
        {sharedWith?.length > 1
          ? `${sharedWith?.length} ${sharedWith?.length === 1 ? t?.('other') : t?.('others')}`
          : `${sharedWith?.[0]?.accountName}`}
      </Tag>
    </Tooltip>
  ) : null;
};
export default SharedTag;
