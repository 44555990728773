import { DownOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-card';
import { Button, DatePicker, DatePickerProps, Segmented, Space, Typography } from 'antd';
import moment from 'moment';
import useLocationStore from 'pages/Network/hook/useLocationStore';
import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useShipmentsSummary } from 'services/api';
import { GetShipmentsSummaryOptionalParams } from 'services/api/client/src';
import LineChart from './LineChart';
import styles from './LineChart/Linechart.module.less';
import ShipmentTable from './ShipmentTable';

const bodyStyle = { padding: '15px', paddingTop: '0px' };
const headStyle = { padding: '15px' };
const dropdownIcon = { fontSize: '10px', color: '#3f51b5' };
const datepicker = { width: '80px' };

const ViewGraph: FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.shipment_card' });
  const shipmentTypes = useMemo(() => [t('outbound'), t('inbound')], [t]);
  const [shipmentType, setShipmentType] = useState<string | number>(shipmentTypes[0]);
  const [mode, setMode] = useState('graph');
  const { urn } = useLocationStore();
  const { locationId } = useParams();
  const [params, setParams] = useState<GetShipmentsSummaryOptionalParams>({
    startDate: new Date(`${new Date().getFullYear()}`),
    endDate: new Date(`${new Date().getFullYear() + 1}`),
  });

  useEffect(() => {
    if (urn) {
      setParams({
        ...params,
        ...(locationId
          ? {
              locationUrn: urn || '',
            }
          : {
              tradePartnerUrn: urn || '',
            }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, urn]);

  const { data, isLoading } = useShipmentsSummary({
    ...params,
  });
  const direction = useMemo(() => {
    switch (true) {
      case shipmentType === t('inbound'):
        return { direction: 'Inbound' };
      case shipmentType === t('outbound'):
        return { direction: 'Outbound' };
      default:
        return {};
    }
  }, [shipmentType, t]);

  const shipParam = useMemo(
    () =>
      direction?.direction === 'Inbound'
        ? {
            ...(locationId
              ? { shippedToUrn: urn || '' }
              : { shippedToTradePartnerUrn: [urn || ''] }),
          }
        : {
            ...(locationId
              ? { shippedFromUrn: urn || '' }
              : { shippedFromTradePartnerUrn: [urn || ''] }),
          },
    [direction, locationId, urn],
  );

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setParams({
      ...params,
      startDate: new Date(dateString),
      endDate: new Date(`${Number(dateString) + 1}`),
    });
  };

  const Title: ReactElement = (
    <Space>
      <Typography.Title level={4} className="mr0">
        {t('shipment_title')}
      </Typography.Title>
      {mode === 'table' && (
        <Segmented
          options={shipmentTypes}
          value={shipmentType || shipmentTypes[0]}
          onChange={setShipmentType}
        />
      )}
      <DatePicker
        value={moment.utc(params?.startDate)}
        suffixIcon={<DownOutlined style={dropdownIcon} />}
        style={datepicker}
        picker="year"
        mode="year"
        bordered={false}
        onChange={onChange}
        allowClear={false}
        className={styles.datepicker}
      />
    </Space>
  );

  return (
    <ProCard
      className={styles.linechartbg}
      bodyStyle={bodyStyle}
      headStyle={headStyle}
      loading={isLoading}
      title={Title}
      extra={
        mode === 'graph' ? (
          <Button onClick={() => setMode('table')} type="link">
            <Space>{t('view_table')}</Space>
          </Button>
        ) : (
          <Button onClick={() => setMode('graph')} type="link">
            <Space>{t('view_graph')}</Space>
          </Button>
        )
      }
    >
      {mode === 'graph' && <LineChart data={data} />}
      {mode === 'table' && (
        <ShipmentTable
          startDate={params.startDate}
          endDate={params.endDate}
          {...direction}
          {...shipParam}
        />
      )}
    </ProCard>
  );
};

export default React.memo(ViewGraph);
