export const setOutboundSettingsTabSession = (tabId: string) => {
  sessionStorage.setItem('OutboundSettingsTab', tabId);
};

export const getOutboundSettingsTabSession = () => {
  if (!sessionStorage.getItem('OutboundSettingsTab')) {
    setOutboundSettingsTabSession('internal');
  }
  return sessionStorage.getItem('OutboundSettingsTab') || 'internal';
};

export const removeOutboundSettingsTabSession = () => {
  sessionStorage.removeItem('OutboundSettingsTab');
};
