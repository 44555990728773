import { Modal, Typography } from 'antd';
import { useCallback } from 'react';
import { getShipmentsT } from 'utils';

const useNewShipmentActions = () => {
  const onShowErrorExternalShipmentDialog = useCallback(() => {
    Modal.error({
      title: getShipmentsT?.('error_warning.unable_to_receive_shipments'),
      content: (
        <Typography.Paragraph>
          {`${getShipmentsT?.('error_warning.unable_to_receive_shipments_content')}`}{' '}
          <Typography.Link type="secondary" href="">
            {getShipmentsT('error_warning.unable_to_receive_shipments_contact')}
          </Typography.Link>
        </Typography.Paragraph>
      ),
      okText: 'Done',
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);

  const onShowMixLotDialog = useCallback(() => {
    Modal.warning({
      title: getShipmentsT?.('mix_lot_serial_title'),
      content: getShipmentsT?.('mix_lot_serial_desc'),
      okText: getShipmentsT?.('mix_lot_serial_confirm'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);

  const onShowMixSSCCDialog = useCallback(() => {
    Modal.warning({
      title: getShipmentsT?.('sscc_selection_title'),
      content: getShipmentsT?.('sscc_selection_desc'),
      okText: getShipmentsT?.('sscc_selection_confirm'),
      cancelText: getShipmentsT?.('sscc_selection_cancel'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);

  return {
    onShowMixLotDialog,
    onShowMixSSCCDialog,
    onShowErrorExternalShipmentDialog,
  };
};
export default useNewShipmentActions;
