import { message, Modal } from 'antd';
import { useShareTemplateStore } from 'components';
import { useModalVisibility } from 'hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  fetchTemplateById,
  useCreateTemplate,
  useDeleteTemplateById,
  useDeleteTemplates,
} from 'services/api';
import { CreateAttributeRequest, CreateEventTemplateRequest } from 'services/api/client/src';
import { errorHandler } from 'utils';
import useAttributeStore from './Tables/useAttributeStore';
import { TemplateItem } from './typings';

const useTemplateActions = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates' });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const shareModal = useModalVisibility();
  const createTemplate = useCreateTemplate(queryClient);
  const deleteTemplate = useDeleteTemplateById(queryClient);
  const deleteTemplates = useDeleteTemplates(queryClient);
  const { setTemplate, setTemplates, setEtradePartners } = useShareTemplateStore();
  const setTemplateMode = useAttributeStore((state) => state.setTemplateMode);

  /* delete template */
  const onDeleteTemplate = useCallback(
    (actionItemKey: string, actionPayload?: TemplateItem, onSuccess?: () => void) => {
      Modal.warning({
        title: t('delete_modal.title', { name: actionPayload?.title || actionPayload?.name || '' }),
        content: t('delete_modal.content'),
        okCancel: true,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okText: t('delete_modal.ok_text'),
        cancelText: t('delete_modal.cancel_text'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onOk: async () => {
          await deleteTemplate.mutateAsync(actionPayload?.id || '0', {
            onSuccess: () => {
              setTimeout(() => {
                message.success(
                  t('delete_success_message', {
                    id: actionPayload?.title || actionPayload?.name || '',
                  }),
                );
              }, 1000);
              onSuccess?.();
            },
          });
        },
        centered: true,
      });
    },
    [deleteTemplate, t],
  );
  /* delete template multiple */
  const onDeleteTemplateMultiple = useCallback(
    (selectedRows: Array<TemplateItem>) => {
      const greaterthanone = selectedRows.length > 1;
      Modal.warning({
        title: greaterthanone
          ? t('delete_modal.title_many', { count: selectedRows.length })
          : t('delete_modal.title', { name: selectedRows?.[0].title }),
        content: greaterthanone ? t('delete_modal.content_many') : t('delete_modal.content'),
        okCancel: true,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okText: t('delete_modal.ok_text'),
        cancelText: t('delete_modal.cancel_text'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onOk: () => {
          deleteTemplates.mutate(selectedRows.map((el) => String(el.id)));
        },
        centered: true,
      });
    },
    [deleteTemplates, t],
  );

  /* duplicate template */
  const createDuplicate = useCallback(
    async (id: string) => {
      const done = message.loading(t('action_progress'), 0);
      try {
        const template = await fetchTemplateById(id);
        const templateFields: CreateAttributeRequest[] =
          template?.templateAttributes?.map((field) => ({
            name: field?.attribute?.name || '',
            fieldProperties: {
              fields: {
                propertyName: field?.attribute?.fieldProperties?.fields?.propertyName,
                fieldType: field?.attribute?.fieldProperties?.fields?.fieldType,
                required: field?.attribute?.fieldProperties?.fields?.required,
                hidden: field?.attribute?.fieldProperties?.fields?.hidden,
              },
              values: {
                valueOptions: field?.attribute?.fieldProperties?.values?.valueOptions,
                defaultValue: field?.attribute?.fieldProperties?.values?.defaultValue,
              },
              location: field?.attribute?.fieldProperties?.location,
              namespace: {
                name: field?.attribute?.fieldProperties?.namespace?.name,
                prefix: field?.attribute?.fieldProperties?.namespace?.prefix,
                uri: 'wholechain.com',
              },
            },
          })) || [];
        const reqBody: CreateEventTemplateRequest = {
          name: `${template?.templateName} ${t('copy_b')}`,
          templateFields,
          templateDocuments:
            template?.templateDocuments?.map((doc) => ({
              name: doc?.documentName,
              required: doc?.required,
              type: doc?.documentType,
            })) || [],
        };
        await createTemplate.mutateAsync(reqBody);
        done();
        message.success(t('duplicate_success_message', { id: template?.templateName }));
      } catch (error) {
        done();
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [createTemplate, t],
  );

  /* share template */
  const onShareTemplate = useCallback(
    (actionPayload?: TemplateItem) => {
      setTemplate(actionPayload);
      setTemplates([]);
      setEtradePartners([
        {
          id: actionPayload?.ownerAccount,
          name: actionPayload?.ownerAccountName || '',
          isOwner: true,
          isUpdated: false,
          isDeleted: false,
        },
        ...(actionPayload?.sharedWith?.map((el) => ({
          id: el?.accountId,
          // @ts-ignore tbd backend
          name: el?.accountName || '',
          // @ts-ignore tbd backend
          permission: (el?.role as 'Viewer' | 'Editor') || ('Viewer' as 'Viewer' | 'Editor'),
          isOwner: false,
          isUpdated: false,
          isDeleted: false,
        })) || []),
      ]);
      shareModal.show();
    },
    [setTemplate, shareModal, setEtradePartners, setTemplates],
  );
  /* share template multiple */
  const onShareTemplateMultiple = useCallback(
    (templates?: Array<string>) => {
      shareModal.show();
      setTemplate(undefined);
      setTemplates(templates);
    },
    [setTemplates, shareModal, setTemplate],
  );

  const handleActionsMenuClick = useCallback(
    (actionItemKey: string, actionPayload?: TemplateItem) => {
      switch (actionItemKey) {
        case 'view_template':
          setTemplateMode?.('view');
          navigate(String(actionPayload?.id || 0));
          break;
        case 'edit_template':
          setTemplateMode?.('edit');
          navigate(String(actionPayload?.id || 0));
          break;
        case 'view_preview':
          navigate(`${actionPayload?.id || 0}/preview`);
          break;
        case 'share':
          onShareTemplate(actionPayload);
          break;
        case 'duplicate':
          createDuplicate(actionPayload?.id || '0');
          break;
        case 'delete':
          onDeleteTemplate(actionItemKey, actionPayload);
          break;

        default:
          setTemplateMode?.('edit');
          break;
      }
    },
    [navigate, setTemplateMode, onShareTemplate, createDuplicate, onDeleteTemplate],
  );
  return {
    shareModal,
    onDeleteTemplate,
    createDuplicate,
    handleActionsMenuClick,
    onShareTemplate,
    onShareTemplateMultiple,
    onDeleteTemplateMultiple,
  };
};
export default useTemplateActions;
