import { TradePartnerItem } from 'components';
import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  CreateEventTemplateRequest,
  GetEventTemplatesOptionalParams,
  ShareEventTemplateOptionalParams,
  ShareEventTemplateRequest,
  UnshareEventTemplateOptionalParams,
  UpdateEventTemplateRequest,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';
import { BulkUpdateDeleteProps } from './typings';

/* get event template by id */
const fetchTemplates = async (params?: GetEventTemplatesOptionalParams) =>
  HttpClient.getEventTemplates(params);

export const fetchTemplateById = async (id: string) => HttpClient.getEventTemplateById(id);

/* create event template */
const createTemplate = async (body: CreateEventTemplateRequest) =>
  HttpClient.createEventTemplate({ body });

/* share a template */
const shareTemplate = async (templateId: string, body?: ShareEventTemplateRequest) =>
  HttpClient.shareEventTemplate(templateId, { body });

/* unshare a template */
const unshareTemplate = async (templateId: string, options?: UnshareEventTemplateOptionalParams) =>
  HttpClient.unshareEventTemplate(templateId, options);

/* update event template */
const updateTemplate = async (id: string, body: UpdateEventTemplateRequest) =>
  HttpClient.updateEventTemplate(id, { body });
const deleteTemplateById = async (id: string) => HttpClient.deleteEventTemplate(id);

const bulkUpdateDelete = (templateId: string, users?: Array<TradePartnerItem>) => {
  const removedUsers = users?.filter((user) => user.isDeleted);
  const updatedUsers = users?.filter((user) => user.isUpdated && !user.isDeleted);
  const updatedPromises =
    updatedUsers?.map((user) =>
      shareTemplate(templateId, {
        wholechainIds: [user?.id || ''],
        role: user.permission,
      }),
    ) || [];

  const removePromises = removedUsers?.length
    ? removedUsers.map((user) =>
        unshareTemplate(templateId, {
          accountId: user?.id || '',
        }),
      )
    : [];

  return Promise.all([...updatedPromises, ...removePromises]);
};
/* queries */
export const useCreateTemplate = (queryClient: QueryClient) =>
  useMutation((body: CreateEventTemplateRequest) => createTemplate(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('templates');
    },
  });

export const useUpdateTemplate = (queryClient: QueryClient) =>
  useMutation(
    ({ id, body }: { id: string; body: UpdateEventTemplateRequest }) => updateTemplate(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('templates');
        queryClient.invalidateQueries('template');
      },
    },
  );

export const useShareTemplate = (queryClient: QueryClient) =>
  useMutation(
    ({ templateId, body }: { templateId: string; body?: ShareEventTemplateRequest }) =>
      shareTemplate(templateId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('templates');
      },
    },
  );
export const useUnshareTemplate = (queryClient: QueryClient) =>
  useMutation(
    ({ templateId, options }: { templateId: string; options?: ShareEventTemplateOptionalParams }) =>
      unshareTemplate(templateId, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('templates');
      },
    },
  );
/* use bulk  update delete */
export const useBulkUpdateDeleteShare = (queryClient: QueryClient) =>
  useMutation(
    ({ templateId, users }: BulkUpdateDeleteProps) => bulkUpdateDelete(templateId, users),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('templates');
      },
    },
  );
export const useDeleteTemplateById = (queryClient: QueryClient) =>
  useMutation((id: string) => deleteTemplateById(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('templates');
    },
  });
export const useDeleteTemplates = (queryClient?: QueryClient) =>
  useMutation((ids: Array<string>) => Promise.all(ids.map(deleteTemplateById)), {
    onSuccess: () => {
      if (queryClient) {
        queryClient.invalidateQueries('templates');
      }
    },
  });
export const useTemplateById = (id: string) =>
  useQuery(['template', id], () => fetchTemplateById(id), {
    // disable refetch on window focus and switch tab
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
const useTemplates = (params?: GetEventTemplatesOptionalParams) =>
  useQuery(['templates', params], () => fetchTemplates(params));

export default useTemplates;
