import { ProColumns } from '@ant-design/pro-table';
import { Segmented, Tag, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SegmentedValue } from 'antd/lib/segmented';
import { Actions, GTable, ShareTemplate, SupportModal, TitleBar } from 'components';
import { useModalVisibility, useTableRowSelection } from 'hooks';
import pluralize from 'pluralize';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAccountSubscription } from 'services/api';
import { featureFlag, supportEmailLink } from 'utils';
import EmptyStateTemplates from './EmptyStateTemplates';
import useAttributeStore from './Tables/useAttributeStore';
import SharedTag from './components';
import { ActionMenu, ActionProps, ColumnsType, TemplateItem, TemplateTitleProps } from './typings';
import useTemplateActions from './useTemplateActions';
import useTemplateFilters from './useTemplateFilters';

const mainActionItems = ({ selectedRowKeys, t }: ActionMenu): Array<ItemType> => [
  {
    key: 'addTemplate',
    label: t?.('action_item.add_template'),
  },
  {
    key: 'manage',
    label: t?.('action_item.manage'),
    type: 'group',
    children: [
      {
        key: 'template_attributes',
        label: t?.('action_item.template_attributes'),
      },
      {
        key: 'share',
        label: t?.('action_item.share'),
        disabled: !((selectedRowKeys?.length || 0) > 0),
      },
      {
        key: 'delete',
        label: t?.('action_item.delete'),
        disabled: !((selectedRowKeys?.length || 0) > 0),
      },
    ],
  },
];
const ActionItems = ({ record, t }: ActionProps): ItemType[] =>
  record?.role === 'Editor'
    ? [
        {
          key: 'view_template',
          label: t?.('action_item.view_template'),
        },
        {
          key: 'edit_template',
          label: t?.('action_item.edit_template'),
        },
        {
          key: 'view_preview',
          label: t?.('action_item.view_preview'),
        },
        {
          key: 'share',
          label: t?.('action_item.share'),
        },
        {
          key: 'duplicate',
          label: t?.('action_item.duplicate'),
        },
        {
          key: 'delete',
          label: t?.('action_item.delete'),
        },
      ]
    : [
        {
          key: 'view_template',
          label: t?.('action_item.view_template'),
        },
        {
          key: 'view_preview',
          label: t?.('action_item.view_preview'),
        },
        {
          key: 'delete',
          label: t?.('action_item.delete'),
        },
      ];
const TemplatesTitle: FC<TemplateTitleProps> = ({
  records,
  selectedRowKeys,
  onRowSelectionChange,
  subscription,
  total,
  supportModal,
  t,
}) => {
  const navigate = useNavigate();
  const { shareModal, onShareTemplateMultiple, onDeleteTemplateMultiple } = useTemplateActions();

  const setTemplateMode = useAttributeStore((state) => state.setTemplateMode);

  // const templateTypes = [t('tab_workflows_title'), t('tab_events_title'), t('tab_documents_title')];
  const templateTypes = [t('tab_events_title'), t('tab_documents_title')];
  const [templateType, setTemplateType] = useState<string | number>(templateTypes[0]);

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'addTemplate':
        if (
          featureFlag?.planCheck
            ? (total || 0) < (subscription?.templates || 0) ||
              !Object.prototype.hasOwnProperty.call(subscription, 'templates')
            : true
        ) {
          setTemplateMode?.('edit');
          navigate('add');
        } else {
          supportModal?.show();
        }

        break;
      case 'template_attributes':
        navigate({ pathname: `../../settings`, search: `?tab=${actionItemKey}` });
        break;
      case 'share':
        onShareTemplateMultiple(selectedRowKeys as Array<string>);
        onRowSelectionChange?.([], []);
        break;
      case 'delete':
        onDeleteTemplateMultiple(records);
        onRowSelectionChange?.([], []);
        break;

      default:
        break;
    }
  };

  const onTemplateTitleChange = (value: SegmentedValue) => {
    setTemplateType(value);
    switch (value) {
      case templateTypes[0]:
        navigate('../../');
        break;
      case templateTypes[1]:
        // navigate('../events');
        // navigate({ pathname: '../../documents' });
        navigate({ pathname: '../documents' });

        break;
      case templateTypes[2]:
        navigate({ pathname: '../../documents' });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ShareTemplate modal={shareModal} />
      <TitleBar
        title={t('title')}
        extraTitle={
          <Segmented
            key="segmented"
            options={templateTypes}
            value={templateType}
            onChange={onTemplateTitleChange}
          />
        }
        extra={
          (selectedRowKeys?.length || 0) > 0 && (
            <Typography.Title level={5} className="selectiontext">
              {`${selectedRowKeys?.length || 0} ${pluralize(
                t('singular'),
                selectedRowKeys?.length || 0,
              )} ${t('selected')}`}
            </Typography.Title>
          )
        }
        actionItems={mainActionItems({ selectedRowKeys, t })}
        onActionItemClick={onActionItemClick}
      />
    </>
  );
};
const columns = ({
  t,
  handleActionsMenuClick,
  setTemplateMode,
  onShareTemplate,
  templateSearchfilter,
  coreCompanyFilter,
}: ColumnsType): ProColumns<TemplateItem>[] => [
  {
    title: t?.('col_template_title'),
    dataIndex: 'name',
    render: (text, record) => (
      <Link
        className="text-link"
        to={record?.id || '0'}
        onClick={() => {
          setTemplateMode?.('view');
        }}
      >
        {text}
      </Link>
    ),
    hideInSetting: true,
    ...templateSearchfilter,
  },
  {
    title: t?.('col_permissions_title'),
    dataIndex: 'role',
    filters: [
      { text: t?.('viewer'), value: 'Viewer' },
      { text: t?.('editor'), value: 'Editor' },
    ],
    render: (text, record) => (
      <Tag className="document-tag">{t?.(`${String(record?.role || '')?.toLowerCase()}`)}</Tag>
    ),
  },
  {
    title: t?.('col_last_edited_title'),
    dataIndex: 'lastModifiedTime',
    ellipsis: true,
    sorter: true,
  },
  {
    title: t?.('col_shared_title'),
    dataIndex: 'sharedWith',
    filters: coreCompanyFilter,
    filterSearch: true,
    ellipsis: true,
    render: (text, record) => (
      <SharedTag record={record} onClick={() => onShareTemplate?.(record)} />
    ),
  },
  {
    dataIndex: 'actions',
    render: (text, record) => (
      <Actions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        items={ActionItems({ record, t })}
        onClick={handleActionsMenuClick}
        actionPayload={record}
      />
    ),
    hideInSetting: true,
  },
];
const Templates = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates' });
  const setTemplateMode = useAttributeStore((state) => state.setTemplateMode);
  const { selectedRowKeys, selectedRows, onRowSelectionChange } = useTableRowSelection();

  const { handleActionsMenuClick, shareModal, onShareTemplate } = useTemplateActions();
  const { data: account } = useAccountSubscription();
  const subscription = useMemo(() => account?.subscriptionDetails || {}, [account]);
  // const subscription = mockSubscription;
  const supportModal = useModalVisibility(false);

  const {
    params,
    totalItems,
    netItemCount,
    records,
    isLoading,
    coreCompanyFilter,
    onTableChange,
    refetch,
    filters: [templateSearchfilter],
  } = useTemplateFilters();

  return (
    <>
      {shareModal?.visible && <ShareTemplate modal={shareModal} />}
      <GTable<TemplateItem>
        columns={columns({
          t,
          handleActionsMenuClick,
          setTemplateMode,
          onShareTemplate,
          templateSearchfilter,
          coreCompanyFilter,
        })}
        headerTitle={
          <TemplatesTitle
            records={selectedRows}
            selectedRowKeys={selectedRowKeys}
            t={t}
            onRowSelectionChange={onRowSelectionChange}
            subscription={subscription}
            total={totalItems}
            supportModal={supportModal}
          />
        }
        value={records}
        loading={isLoading}
        enableRowSelection
        pagination={{
          defaultPageSize: params.pageSize,
          total: totalItems,
          showTotal: (total, range) =>
            t('page_range', {
              from: range[0],
              to: range[1],
              total,
            }),
        }}
        scroll={netItemCount > 0 ? { y: '75vh', x: 1045 } : undefined}
        emptyContainer={!netItemCount ? <EmptyStateTemplates t={t} /> : undefined}
        showHeader={netItemCount > 0}
        rowSelection={{
          selectedRowKeys,
          onChange: onRowSelectionChange,
          columnWidth: 30,
          preserveSelectedRowKeys: true,
        }}
        columnsState={{
          persistenceKey: 'pages-event-templates',
        }}
        // @ts-ignore
        onTableChange={onTableChange}
        options={{
          reload: () => refetch(),
        }}
      />
      <SupportModal
        modal={supportModal}
        description={
          <div>
            {t('upgrade_modal_description')}&nbsp;
            <a href={supportEmailLink} type="mail">
              {t('support_anchor_email_title')}
            </a>
            &nbsp;
            {t('upgrade_modal_desc2')}
          </div>
        }
      />
    </>
  );
};

export default React.memo(Templates);
