import { Segmented, Tabs, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SegmentedValue } from 'antd/lib/segmented';
import { TitleBar } from 'components';
import { useTableRowSelection } from 'hooks';
import { useDocumentActions } from 'pages/Documents';
import UpgradeModal from 'pages/Network/forms/AddEdit/UpgradeModal';
import useDeleteActions from 'pages/Network/hook/useDeleteActions';
import { useProductActions } from 'pages/Products';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAccountSubscription } from 'services/api';
import { capitalizeFirstLetter, featureFlag } from 'utils';
import { Brands, Documents, Locations, Products, QRCodes } from './components';
import styles from './index.module.less';
import { ActionItemProps } from './typings';

const ActionItems = ({ selectedRowKeys, currentTab, t }: ActionItemProps): Array<ItemType> => [
  {
    key: 'manage',
    label: t?.('manage'),
    type: 'group',
    children: [
      ...(currentTab === 'Products'
        ? [
            {
              key: 'move_to_products',
              label: t?.('move_to_products'),
              disabled: selectedRowKeys?.length === 0,
            },
          ]
        : []),
      ...(currentTab === 'Documents'
        ? [
            {
              key: 'move_to_documents',
              label: t?.('move_to_documents'),
              disabled: selectedRowKeys?.length === 0,
            },
          ]
        : []),

      ...(currentTab === 'Locations'
        ? [
            {
              key: 'move_to_network',
              label: t?.('move_to_network'),
              disabled: selectedRowKeys?.length === 0,
            },
          ]
        : []),
      ...(currentTab === 'Brands'
        ? [
            {
              key: 'move_to_brands',
              label: t?.('move_to_brands'),
              disabled: selectedRowKeys?.length === 0,
            },
          ]
        : []),
      ...(currentTab === 'QR Codes'
        ? [
            {
              key: 'move_to_qr_codes',
              label: t?.('move_to_qr_codes'),
              disabled: selectedRowKeys?.length === 0,
            },
          ]
        : []),
    ],
  },
];
const sections = ['Products', 'Documents', 'Locations', 'Brands', 'QR Codes'];
const mainSections = ['Wholechain', 'Sourceview'];

const Archived = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.archived' });
  const [queryParams] = useSearchParams();
  const activeSection = capitalizeFirstLetter(queryParams?.get('section') || 'Products');
  const { data: accountData } = useAccountSubscription();

  const [section, setSection] = useState<SegmentedValue>(activeSection);
  const [mainSection, setMainSection] = useState<SegmentedValue>(mainSections[0]);
  const apiIntegration = true;
  const showSourceView = false;

  const mainSegmentedOptions = useMemo(
    () => [
      { value: mainSections[0], label: t?.('wholechain_title') },
      { value: mainSections[1], label: t?.('sourceview_title') },
    ],
    [t],
  );
  const { selectedRowKeys, onRowSelectionChange } = useTableRowSelection();
  const { onUnarchiveProductMultiple } = useProductActions();
  const { onUnarchiveDocumentMultiple } = useDocumentActions();
  const { onUnarchiveMultipleLocations, upgradeModal } = useDeleteActions();
  const onTabChange = (activeKey: SegmentedValue) => {
    setSection(activeKey);
    onRowSelectionChange([], []);
  };

  const onMainTabChange = (activeKey: SegmentedValue) => {
    setMainSection(activeKey);
    onRowSelectionChange([], []);
  };
  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'move_to_products':
        onUnarchiveProductMultiple(selectedRowKeys);
        onRowSelectionChange([], []);
        break;
      case 'move_to_documents':
        onUnarchiveDocumentMultiple(selectedRowKeys);
        onRowSelectionChange([], []);
        break;
      case 'move_to_network':
        onUnarchiveMultipleLocations(selectedRowKeys);
        onRowSelectionChange([], []);
        break;

      default:
        break;
    }
  };

  return (
    <>
      {upgradeModal.visible && <UpgradeModal modal={upgradeModal} tkey="archive_loc." />}
      <TitleBar
        title={`${t?.('title')} `}
        extraTitle={
          apiIntegration &&
          (featureFlag?.planCheck ? accountData?.subscriptionDetails?.sourceviewAddon : true) &&
          showSourceView && (
            <Segmented
              value={mainSection}
              options={mainSegmentedOptions}
              onChange={onMainTabChange}
            />
          )
        }
        actionItems={ActionItems({ currentTab: section, selectedRowKeys, t })}
        onActionItemClick={onActionItemClick}
        className={styles.header}
        extra={
          selectedRowKeys.length > 0 && (
            <Typography.Title level={5} className="selectiontext">
              {`${selectedRowKeys.length} ${
                selectedRowKeys.length === 1 ? t('item') : t('items')
              } ${t('selected')}`}
            </Typography.Title>
          )
        }
      />

      {mainSection === mainSections[0] && (
        <Tabs defaultActiveKey={sections[0]} onChange={onTabChange}>
          <Tabs.TabPane tab={t?.('products_title')} key={sections[0]}>
            <Products
              selectedRowKeys={selectedRowKeys}
              onRowSelectionChange={onRowSelectionChange}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t?.('documents_title')} key={sections[1]}>
            <Documents
              selectedRowKeys={selectedRowKeys}
              onRowSelectionChange={onRowSelectionChange}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t?.('location_title')} key={sections[2]}>
            <Locations
              selectedRowKeys={selectedRowKeys}
              onRowSelectionChange={onRowSelectionChange}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
      {mainSection === mainSections[1] && showSourceView && (
        <Tabs defaultActiveKey={sections[3]} onChange={onTabChange}>
          <Tabs.TabPane tab={t?.('brands_title')} key={sections[3]}>
            <Brands selectedRowKeys={selectedRowKeys} onRowSelectionChange={onRowSelectionChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t?.('qr_codes_title')} key={sections[4]}>
            <QRCodes
              selectedRowKeys={selectedRowKeys}
              onRowSelectionChange={onRowSelectionChange}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </>
  );
};
export default Archived;
