import type { ActionType, ProColumns } from '@ant-design/pro-table';
import { Typography } from 'antd';
import GTable from 'components/GTable';
import { useModalVisibility } from 'hooks';
import { FilterArrayProp } from 'pages/Network/typings';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { useTradePartners } from 'services/api';
import { EventResponse } from 'services/api/client/src';
import { getTransformType } from 'utils';

import { useShipEventByIdShipmentsMutate } from 'services/api/newShipments';
import DetailsModal from './DetailsModal/DetailsModal';
import { EventListItem, EventListProps, OnInfoModalClickFn } from './typings';
import useEventListActions from './useShipEventListActions';

const EventList: FC<EventListProps> = () => {
  const actionRef = useRef<ActionType>();
  const { shipmentId = '1' } = useParams();
  const {
    records,
    coreLocations,
    totalItems,
    onTableChange,
    isLoading,
    coreEventTypes,
    filters: [primaryIdSearchfilter],
    productIdFilter,
    productNameFilter,
  } = useEventListActions(shipmentId);
  const infoModal = useModalVisibility(false);
  const [activeEvent, setActiveEvent] = useState<EventResponse>();
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details.event_list' });
  const [queryParams] = useSearchParams();
  const hideDocument = queryParams?.get('hideDocument') === 'true' || false;
  const getEventCall = useShipEventByIdShipmentsMutate();
  const { data: allCompany } = useTradePartners();

  const onInfoModalClick = useCallback<OnInfoModalClickFn>(
    async (evt) => {
      const eventData = await getEventCall?.mutateAsync({
        shipmentIds: evt?.eventId || '',
      });
      setActiveEvent(eventData);
      infoModal?.show();
    },
    [getEventCall, infoModal],
  );

  const tradePartnersFilter: Array<FilterArrayProp> = useMemo(
    () =>
      allCompany?.data?.map((item) => ({
        text: item.name || '',
        value: item.id || '',
      })) || [],
    [allCompany],
  );

  const columns: Array<ProColumns<EventListItem>> = [
    {
      title: t('product_name'),
      dataIndex: 'productName',
      ellipsis: true,
      width: '8%',
      filters: productNameFilter,
      filterSearch: true,
    },
    {
      title: t('product_identifier'),
      dataIndex: 'productId',
      ellipsis: true,
      width: '8%',
      filters: productIdFilter,
      filterSearch: true,
    },
    {
      title: t('primary_id'),
      dataIndex: 'primaryId',
      render: (text, record) => (
        <Link
          to={record?.company || ''}
          className="text-link"
          onClick={(e) => {
            e.preventDefault();
            onInfoModalClick(record?.itemProps);
          }}
        >
          {text}
        </Link>
      ),
      width: '8%',
      ...primaryIdSearchfilter,
    },
    {
      title: t('quantity'),
      sorter: true,
      dataIndex: 'quantity',
      width: '8%',
    },
    {
      title: t('event'),
      dataIndex: 'event',
      filters: coreEventTypes,
      width: '8%',
      render: (text, record) => (
        <Typography.Text>
          {record?.event || ''}{' '}
          <Typography.Text type="secondary">
            {getTransformType(record?.itemProps?.transformType)}
          </Typography.Text>
        </Typography.Text>
      ),
    },
    {
      title: t('company'),
      dataIndex: 'company',
      filters: tradePartnersFilter,
      filterSearch: true,
      width: '8%',
    },
    {
      title: t('location'),
      dataIndex: 'location',
      filters: coreLocations,
      filterSearch: true,
      width: '8%',
    },
    {
      title: t('event_date'),
      sorter: true,
      dataIndex: 'eventDate',
      width: '8%',
    },
  ];

  return (
    <>
      <DetailsModal
        key={activeEvent?.id}
        modalProps={infoModal}
        activeEvent={activeEvent}
        hideDocument={hideDocument}
      />
      <GTable<EventListItem>
        actionRef={actionRef}
        columns={columns}
        value={records}
        pagination={{
          // defaultPageSize: params.pageSize,
          total: totalItems,
        }}
        scroll={{ x: 1500, y: '75vh' }}
        options={{
          reload: false,
          setting: false,
        }}
        onTableChange={onTableChange}
        loading={isLoading}
      />

      {/* Outlet to render the sub-routes */}
      <Outlet />
    </>
  );
};

export default React.memo(EventList);
