import { FilterOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { TitleBar } from 'components';
import useTableRowSelection from 'hooks/useTableRowSelection';
import { TabTitle } from 'pages/Dashboard';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useListShipMutate } from 'services/api';
import {
  useCountOutboundShipmentsShipments,
  useInboundListShipMutate,
} from 'services/api/newShipments';
import { dateLocaleFormat, dateLocalFormat, downloadJsonToCSV, mapShipInvItem } from 'utils';
import { ShipmentTitleProps } from '../typings';
import styles from './index.module.less';
import OutboundInternalShipments from './OutboundInternalShipments';
import {
  getOutboundSettingsTabSession,
  setOutboundSettingsTabSession,
} from './OutboundTabSettings';

export const ShipmentsTitle: FC<ShipmentTitleProps> = ({ t, showFilter, setShowFilter }) => (
  <TitleBar
    title={`${t('outbound')} ${t('shipment_title')}`}
    extra={
      <Button type="link" onClick={() => setShowFilter && setShowFilter(!showFilter)}>
        <FilterOutlined />
        {showFilter === true ? t('hide_filter') : t('show_filter')}
      </Button>
    }
  />
);

/**
 * Component to manage the inventory of a product.
 */
const ShipmentOutbound = () => {
  const sessionTab: string = getOutboundSettingsTabSession();
  const [currentTab, setCurrentTab] = useState(sessionTab);
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  const { onRowSelectionChange, selectedRows, selectedRowKeys } = useTableRowSelection();
  const { setProducts, setSelectedShipments } = useShipmentStore();
  const listShipMutate = useListShipMutate();
  const listExternalShipMutate = useInboundListShipMutate();
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const { data: dataProductCounts } = useCountOutboundShipmentsShipments();
  const totalItemsInternal = dataProductCounts?.internal || 0;
  const totalItemsWholechain = dataProductCounts?.wholechain || 0;
  const totalItemsOtherSystems = dataProductCounts?.otherSystems || 0;
  useEffect(() => {
    setCurrentTab(sessionTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChange = (activeKey: string) => {
    setOutboundSettingsTabSession(activeKey);
    setCurrentTab(activeKey);
    onRowSelectionChange([], []);
  };

  const onDownloadCSVMulti = useCallback(async () => {
    if (currentTab === 'wholechain') {
      const data = await listShipMutate.mutateAsync({
        options: {
          direction: 'Outbound',
          sortBy: '-eventDate',
          status: ['Pending', 'Rejected'],
          onlyMyShipments: true,
        },
      });
      const records = data?.results?.map(mapShipInvItem);
      downloadJsonToCSV(records);
    } else {
      const data = await listExternalShipMutate.mutateAsync({
        options: {
          sortBy: '-eventDate',
          status: ['Pending', 'Rejected'],
        },
      });
      const records = data?.results?.map(
        (item) => ({
          ...item,
          loggedDate: dateLocalFormat(new Date(item?.loggedDate || new Date())),
          eventDate: dateLocaleFormat(new Date(item?.eventDate || new Date())),
        }),
        [],
      );
      downloadJsonToCSV(records);
    }
  }, [currentTab, listExternalShipMutate, listShipMutate]);

  return (
    <>
      <div className={styles.titlebarwarp}>
        <ShipmentsTitle
          onRowSelectionChange={onRowSelectionChange}
          onDownloadCSVMultiple={onDownloadCSVMulti}
          selectedRowKeys={selectedRowKeys}
          selectedRows={selectedRows}
          t={t}
          setProducts={setProducts}
          setSelectedShipments={setSelectedShipments}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      </div>
      <Tabs defaultActiveKey="internal" activeKey={currentTab} onChange={onTabChange}>
        <Tabs.TabPane
          tab={
            <TabTitle
              activeTab={currentTab === 'internal'}
              showZero
              title={t('tab.internal')}
              count={totalItemsInternal}
            />
          }
          key="internal"
        >
          <OutboundInternalShipments
            onRowSelectionChange={onRowSelectionChange}
            selectedRowKeys={selectedRowKeys}
            showFilter={showFilter}
            outboundShipmentType="Internal"
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <TabTitle
              activeTab={currentTab === 'wholechain'}
              showZero
              title={t('tab.wholechain')}
              count={totalItemsWholechain}
            />
          }
          key="wholechain"
        >
          <OutboundInternalShipments
            onRowSelectionChange={onRowSelectionChange}
            selectedRowKeys={selectedRowKeys}
            showFilter={showFilter}
            outboundShipmentType="Wholechain"
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <TabTitle
              activeTab={currentTab === 'other_systems'}
              showZero
              title={t('tab.other_systems')}
              count={totalItemsOtherSystems}
            />
          }
          key="other_systems"
        >
          <OutboundInternalShipments
            onRowSelectionChange={onRowSelectionChange}
            selectedRowKeys={selectedRowKeys}
            showFilter={showFilter}
            outboundShipmentType="OtherSystem"
          />
        </Tabs.TabPane>
      </Tabs>
      {/* Outlet used to render the drawers */}
      <Outlet />
    </>
  );
};

export default React.memo(ShipmentOutbound);
