import { ProColumns } from '@ant-design/pro-table';
import { TablePaginationConfig } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import { Actions, GTable } from 'components';
import ShipmentTag from 'pages/Shipments/components';
import useShipmentStore, {
  FilterType,
  useShipFilters,
  useShipmentActions,
} from 'pages/Shipments/hooks';
import { ActionMenuProps, Shipment } from 'pages/Shipments/typings';
import React, { useCallback, useEffect, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { GetPaginatedShipmentsOptionalParams } from 'services/api/client/src';
import { camelizeFirstChar, getProductsT, getUom } from 'utils';
import styles from '../../../index.module.less';

const InboundActionMenuItems = ({ t, record }: ActionMenuProps): Array<ItemType> => [
  {
    key: 'view_event',
    label: t('actions.view_event_label'),
    disabled: record?.shipmentType === 'Wholechain',
  },
  {
    key: 'events',
    type: 'group',
    label: t('actions.events_grp_label'),
    children: [
      {
        key: 'receive',
        label: t('actions.receive'),
        disabled: record?.status === 'Rejected',
      },
      {
        key: 'reject',
        label: t('actions.reject'),
        disabled: record?.status === 'Rejected',
      },
    ],
  },

  {
    key: 'manage',
    type: 'group',
    label: t('actions.manage_grp_label'),
    children: [
      {
        key: 'download_csv',
        label: t('actions.download_xls_selection'),
      },
    ],
  },
];

const OutboundActionMenuItems = ({ t, record }: ActionMenuProps): Array<ItemType> => [
  {
    key: 'view_event',
    label: t('actions.view_event_label'),
  },
  ...(record?.shipmentType === 'NonWholechain'
    ? [
        {
          key: 'receive',
          label: t('actions.receive'),
        },
      ]
    : []),
  {
    key: 'view_ticket',
    label: t('actions.view_ticket_label'),
    disabled: !record?.ticketId,
  },
  {
    key: 'manage',
    type: 'group',
    label: t('actions.manage_grp_label'),
    children: [
      {
        key: 'download_xls',
        label: t('actions.download_xls_selection'),
        disabled: false,
      },
    ],
  },
];
type ColumnsType = {
  coreTradePartners: ProColumns['filters'];
  productSearchfilter: ProColumns;
  primaryIdSearchfilter: ProColumns;
  logisticIdSearchfilter: ProColumns;
  ticketIdSearchfilter: ProColumns;
  purchaseOrderSearchfilter: ProColumns;
  actionsMenuClick: (actionItemKey: string, actionPayload?: Shipment) => void;
  t: TFunction<'pages', 'shipments.inbound'>;
  shouldDisableReceiveNavigation: (record?: Shipment) => boolean;
  coreStatus: Array<FilterType>;
};

const OutboundColumns = ({
  actionsMenuClick,
  shouldDisableReceiveNavigation,
  productSearchfilter,
  primaryIdSearchfilter,
  purchaseOrderSearchfilter,
  coreTradePartners,
  coreStatus,
  t,
}: ColumnsType): ProColumns<Shipment>[] => [
  {
    title: t('tbl.col_purchase_order_title'),
    dataIndex: 'purchaseOrderNumber',
    sorter: false,
    ellipsis: true,
    width: '14%',
    ...purchaseOrderSearchfilter,
  },
  {
    title: t('tbl.col_product_title'),
    dataIndex: 'productName',
    sorter: false,
    hideInSetting: true,
    width: '14%',
    render: (dom, record) =>
      `${record?.productName || record?.containerItems?.[0]?.productName || ''}`,
    ...productSearchfilter,
  },
  {
    title: t('tbl.col_primary_id_title'),
    dataIndex: 'primaryId',
    sorter: false,
    width: '11%',
    render: (text, record) =>
      record?.id ? (
        <Link
          className="text-link"
          to={{
            pathname: `/products/${record?.productId}/${String(
              record?.id || record?.containerId || '',
            )}`,
            search: `?eventId=${record?.shipmentEventId || ''}${
              record?.isContainer ? '&isContainer=true' : ''
            }`,
          }}
          state={{ from: window.location.pathname }}
        >
          {`${
            record?.isContainer
              ? `${getProductsT('SSCC')}:`
              : `${getProductsT(record?.productIdentifierType || 'Lot')}:`
          } ${record?.primaryId || ''}`}
        </Link>
      ) : (
        `${
          record?.isContainer
            ? `${getProductsT('SSCC')}:`
            : `${getProductsT(record?.productIdentifierType || 'Lot')}:`
        } ${record?.primaryId || ''}`
      ),
    ellipsis: true,
    ...primaryIdSearchfilter,
  },
  {
    title: t('tbl.col_quantity_title'),
    dataIndex: 'quantity',
    render: (text, record) => `${record?.quantity || ''} ${getUom({ product: record }) || 'LBS'}`,
    ellipsis: true,
    width: '8%',
  },
  {
    title: t('tbl.col_shipped_from_title'),
    dataIndex: 'senderName',
    hideInSetting: true,
    filters: coreTradePartners,
    filterSearch: true,
    ellipsis: true,
    width: '14%',
  },
  {
    title: t('tbl.col_logged_date_title'),
    dataIndex: 'loggedDate',
    sorter: true,
    ellipsis: true,
    width: '9%',
  },
  {
    title: t('tbl.col_status_title'),
    dataIndex: 'status',
    filters: coreStatus,
    width: '9%',
    ellipsis: true,
    render: (text, record) => <ShipmentTag status={record.status} />,
  },
  {
    title: t('tbl.col_event_date_title'),
    dataIndex: 'eventDate',
    sorter: true,
    ellipsis: true,
    width: '11%',
  },
  {
    dataIndex: 'actions',
    hideInTable: true,
    render: (text, record) =>
      record?.id ? (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          items={OutboundActionMenuItems({ t, record })}
          disabled={shouldDisableReceiveNavigation(record)}
          onClick={actionsMenuClick}
          actionPayload={record}
        />
      ) : undefined,
    fixed: 'right',
    width: '10%',
    hideInSetting: true,
  },
];

const InboundColumns = ({
  actionsMenuClick,
  shouldDisableReceiveNavigation,
  coreTradePartners,
  productSearchfilter,
  primaryIdSearchfilter,
  purchaseOrderSearchfilter,
  coreStatus,
  t,
}: ColumnsType): ProColumns<Shipment>[] => [
  {
    title: t('tbl.col_purchase_order_title'),
    dataIndex: 'purchaseOrderNumber',
    sorter: false,
    ellipsis: true,
    width: '14%',
    ...purchaseOrderSearchfilter,
  },
  {
    title: t('tbl.col_product_title'),
    dataIndex: 'productName',
    sorter: false,
    width: '14%',
    hideInSetting: true,
    ellipsis: true,
    render: (dom, record) =>
      `${record?.productName || record?.containerItems?.[0]?.productName || ''}`,
    ...productSearchfilter,
  },
  {
    title: t('tbl.col_primary_id_title'),
    dataIndex: 'primaryId',
    sorter: false,
    width: '11%',
    ellipsis: true,
    render: (text, record) =>
      record?.id ? (
        <Link
          className="text-link"
          to={{
            pathname: `/products/${record?.productId}/${String(
              record?.id || record?.containerId || '',
            )}`,
            search: `?eventId=${record?.shipmentEventId || ''}${
              record?.isContainer ? '&isContainer=true' : ''
            }`,
          }}
          state={{ from: window.location.pathname }}
        >
          {`${
            record?.isContainer
              ? `${getProductsT('SSCC')}:`
              : `${getProductsT(record?.productIdentifierType || 'Lot')}:`
          } ${record?.primaryId || ''}`}
        </Link>
      ) : (
        `${
          record?.isContainer
            ? `${getProductsT('SSCC')}:`
            : `${getProductsT(record?.productIdentifierType || 'Lot')}:`
        } ${record?.primaryId || ''}`
      ),
    ...primaryIdSearchfilter,
  },
  {
    title: t('tbl.col_quantity_title'),
    dataIndex: 'quantity',
    width: '8%',
    ellipsis: true,
    render: (text, record) => `${record?.quantity || ''} ${getUom({ product: record }) || 'LBS'}`,
  },
  {
    title: t('tbl.col_shipped_to_title'),
    dataIndex: 'receiverName',
    hideInSetting: true,
    filters: coreTradePartners,
    filterSearch: true,
    ellipsis: true,
    width: '14%',
  },
  {
    title: t('tbl.col_logged_date_title'),
    dataIndex: 'loggedDate',
    sorter: true,
    defaultSortOrder: 'descend',
    ellipsis: true,
    width: '9%',
  },
  {
    title: t('tbl.col_status_title'),
    dataIndex: 'status',
    filters: coreStatus,
    width: '9%',
    ellipsis: true,
    render: (text, record) => <ShipmentTag status={record.status} />,
  },
  {
    title: t('tbl.col_event_date_title'),
    dataIndex: 'eventDate',
    sorter: true,
    ellipsis: true,
    width: '11%',
  },
  {
    dataIndex: 'actions',
    hideInTable: true,
    render: (text, record) =>
      record?.id ? (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          disabled={shouldDisableReceiveNavigation(record)}
          items={InboundActionMenuItems({ t, record })}
          onClick={actionsMenuClick}
          actionPayload={record}
        />
      ) : undefined,
    fixed: 'right',
    width: '10%',
    hideInSetting: true,
  },
];

const Shipments = (param: GetPaginatedShipmentsOptionalParams) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  const { locationId, companyId } = useParams();
  const navigate = useNavigate();
  const { onRejectShipment } = useShipmentActions();
  const { setProducts, setAdvancedSettings, processingReceiveIds } = useShipmentStore();
  const { direction } = param;
  const initparams = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: 20,
      sortBy: '-LoggedDate',
      onlyMyShipments: false,
      ...param,
    }),
    [param],
  );
  const {
    records,
    totalItems,
    isLoading,
    params,
    coreTradePartners,
    coreStatus,
    setParams,
    filters: [
      productSearchfilter,
      primaryIdSearchfilter,
      ticketIdSearchfilter,
      purchaseOrderSearchfilter,
      logisticIdSearchfilter,
    ],
  } = useShipFilters(initparams);

  const shouldDisableReceiveNavigation = useCallback(
    (record?: Shipment) => processingReceiveIds?.includes(record?.id!) ?? false,
    [processingReceiveIds],
  );

  useEffect(() => {
    setParams({
      ...param,
      ...initparams,
      pageNumber: 1,
      pageSize: 20,
      sortBy: '-LoggedDate',
      onlyMyShipments: false,
      direction,
    });
  }, [direction, initparams, setParams, locationId, companyId, param]);

  const onReject = async (actionPayload?: Shipment) => {
    onRejectShipment(actionPayload);
  };
  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, Array<string> | null>,
      sorter: any,
    ) => {
      const apiFilters: GetPaginatedShipmentsOptionalParams = {
        ...(filters?.status?.length && { status: filters?.status }),
        ...(params?.direction === 'Outbound' && {
          shippedFromTradePartnerUrn: filters?.senderName?.length
            ? filters?.senderName
            : initparams?.shippedFromTradePartnerUrn || undefined,
        }),
        ...(params?.direction === 'Inbound' && {
          shippedToTradePartnerUrn: filters?.receiverName?.length
            ? filters?.receiverName
            : initparams?.shippedToTradePartnerUrn || undefined,
        }),
      };
      if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
        setParams({
          ...params,
          ...apiFilters,
          sortBy: sorter?.field
            ? `${sorter.order === 'ascend' ? '+' : '-'}${camelizeFirstChar(sorter.field)}`
            : undefined,
          pageNumber: 1,
        });
      } else {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: pagination?.current,
          pageSize: pagination?.pageSize,
        });
      }
    },
    [
      initparams?.shippedFromTradePartnerUrn,
      initparams?.shippedToTradePartnerUrn,
      params,
      setParams,
    ],
  );
  const actionsMenuClick = (actionItemKey: string, actionPayload?: Shipment) => {
    const recordId = actionPayload?.id || '';
    const productsSearchParams = createSearchParams({
      products: String(recordId),
      locationId: actionPayload?.destinationId || '',
      locationName: actionPayload?.destinationName || '',
      shippedFrom: actionPayload?.senderName || '',
      ...(actionPayload?.isContainer && { containers: actionPayload?.containerId || '' }),
    });

    switch (actionItemKey) {
      case 'reject':
        onReject(actionPayload);
        break;
      case 'receive':
        navigate({
          pathname: `/products/${
            actionPayload?.productId ||
            actionPayload?.containerItems?.[0]?.productId ||
            actionPayload?.externalShipmentId ||
            ''
          }/events/receive`,
          search: actionPayload ? `?${productsSearchParams}` : undefined,
        });
        setProducts(actionPayload ? [actionPayload] : undefined);
        break;
      case 'view_event':
        navigate({
          pathname: `/products/${
            actionPayload?.productId || actionPayload?.containerItems?.[0]?.productId
          }/${String(actionPayload?.id || actionPayload?.containerId || '')}`,
          search: `?eventId=${actionPayload?.shipmentEventId || '1'}${
            actionPayload?.isContainer ? '&isContainer=true' : ''
          }`,
        });
        break;
      case 'view_ticket':
        navigate(`../tickets/${actionPayload?.ticketId || 1}`);
        break;
      case 'download_xls':
        break;

      default:
        break;
    }
    setAdvancedSettings(false);
  };

  return (
    <GTable<Shipment>
      columns={
        direction === 'Inbound'
          ? InboundColumns({
              actionsMenuClick,
              shouldDisableReceiveNavigation,
              coreTradePartners,
              productSearchfilter,
              primaryIdSearchfilter,
              logisticIdSearchfilter,
              ticketIdSearchfilter,
              purchaseOrderSearchfilter,
              coreStatus,
              t,
            })
          : OutboundColumns({
              actionsMenuClick,
              shouldDisableReceiveNavigation,
              productSearchfilter,
              primaryIdSearchfilter,
              logisticIdSearchfilter,
              ticketIdSearchfilter,
              purchaseOrderSearchfilter,
              coreTradePartners,
              coreStatus,
              t,
            })
      }
      className={classNames(styles.shipmenttable)}
      value={records}
      scroll={{ x: 1300, y: 170 }}
      options={{ setting: false, reload: false }}
      loading={isLoading}
      // @ts-ignore
      onTableChange={onTableChange}
      pagination={{
        defaultPageSize: params.pageSize,
        total: totalItems,
        showTotal: (total, range) =>
          t('shipment_table_pagi', { range0: range[0], range1: range[1], total }),
      }}
    />
  );
};

export default React.memo(Shipments);
