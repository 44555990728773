import type { ActionType, ListToolBarProps, ProColumns } from '@ant-design/pro-table';
import { TablePaginationConfig } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions } from 'components';
import GTable from 'components/GTable';
import useModalVisibility, { UseModalVisibilityReturnType } from 'hooks/useModalVisibility';
import { useEventsStore } from 'pages/Events';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  EventResponse,
  GetPaginatedOtherSystemShipmentsOptionalParams,
  InboundShipmentResponse,
  OtherSystemShipmentResponse,
} from 'services/api/client/src';
import {
  useGetExternalShipmentDetailsByIdMutate,
  useInboundOtherSystemShipments,
} from 'services/api/newShipments';
import { dateLocaleFormat } from 'utils';
import useShipmentStore from '../../Shipments/hooks';
import useNewShipmentActions from '../hooks/useNewShipmentActions';
import NoShipmentsFound from '../NoShipmentsFound';
import DetailsModal from '../PreviewEvent/DetailsModal/DetailsModal';
import ShipmentToolbar from '../ShipmentToolbar';
import { InboundInternalShipmentProps } from '../typings';
import InboundExternalShipmentDetailsTable from './InboundExternalShipmentDetailsTable';
import InboundShipmentTableRowData from './InboundShipmentTableRowData';

type RowActionsType = {
  t?: TFunction<'pages', 'shipments'>;
};
const rowActionItems = ({ t }: RowActionsType): Array<ItemType> => [
  {
    key: 'preview_shipment',
    label: t?.('preview_shipment'),
  },
  {
    key: 'receive',
    label: t?.('receive'),
  },
  /* {
    key: 'reject',
    label: t?.('reject'),
  }, */
];

type ColumnsType = {
  t?: TFunction<'pages', 'shipments'>;
  supportModal?: UseModalVisibilityReturnType;
  productNameSearchfilter?: ProColumns;
  onRowActionClick: (actionItemKey: string, record?: InboundShipmentResponse) => void;
};

const columns = ({
  t,
  productNameSearchfilter,
  onRowActionClick,
}: ColumnsType): Array<ProColumns<InboundShipmentResponse>> => [
  {
    dataIndex: 'shipmentId',
    hideInTable: true,
  },
  {
    title: t?.('col_product'),
    dataIndex: 'originCompanyName',
    hideInSetting: true,
    render: (text, record) => <InboundShipmentTableRowData record={record} />,
    ellipsis: true,
    ...productNameSearchfilter,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: 120,
    hideInSetting: true,
    fixed: 'right',
    render: (text, record) =>
      record?.shipmentId ? (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
            type: 'link',
          }}
          items={rowActionItems({ t })}
          onClick={onRowActionClick}
          actionPayload={record}
        />
      ) : undefined,
  },
];

const InboundExternalSystemShipments: FC<InboundInternalShipmentProps> = ({
  selectedRowKeys,
  onRowSelectionChange,
  showFilter,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'global_search.shipments_search_table' });
  const { setSelectedShipments } = useShipmentStore();
  const { setAdvancedSettings, setDataEntryMethod, setTemplate } = useEventsStore();
  const navigate = useNavigate();
  const actionRef = useRef<ActionType>();
  const [params, setParams] = useState<GetPaginatedOtherSystemShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    sortBy: '-eventDate',
    status: ['Pending'],
  });
  const { data, isLoading } = useInboundOtherSystemShipments(params);
  const shipDetails = useGetExternalShipmentDetailsByIdMutate();
  const [activeEvent, setActiveEvent] = useState<EventResponse>();
  const infoModal = useModalVisibility(false);
  const { onShowErrorExternalShipmentDialog } = useNewShipmentActions();

  const expandedRowRender = (row: InboundShipmentResponse) => (
    <InboundExternalShipmentDetailsTable shipmentId={row?.shipmentId} />
  );

  const onTableChange = (pagination: TablePaginationConfig, sorter: any) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };

  const onViewEvent = useCallback(
    (record?: InboundShipmentResponse) => {
      if (record?.shipmentStatus === 'Error') {
        onShowErrorExternalShipmentDialog();
      } else {
        const productsSearchParams = createSearchParams({
          shipmentId: record?.shipmentId || '',
          shipmentType: 'External',
          tradePartnerUrn: record?.destinationCompanyUrn || '',
          locationUrn: record?.destinationLocationUrn || '',
          locationId: record?.destinationLocationId || '',
          eventDate: dateLocaleFormat(record?.eventDate || new Date()) || '',
          isOtherSystem: String(true),
        });
        // sets signle shippment details in store
        setSelectedShipments([record as InboundShipmentResponse]);
        navigate({
          pathname: `/products/${record?.shipmentId}/events/receive`,
          search: record ? `?${productsSearchParams}` : undefined,
        });
      }
    },
    [navigate, onShowErrorExternalShipmentDialog, setSelectedShipments],
  );

  const onPreviewEvent = useCallback(
    async (actionPayload?: InboundShipmentResponse) => {
      const res = await shipDetails?.mutateAsync({
        shipmentIds: [actionPayload?.shipmentId || ''],
        options: {},
      });
      const response: OtherSystemShipmentResponse = res?.[0];
      const shipInfo: EventResponse = {
        id: response?.shipmentId,
        eventType: 'Ship',
        locationId: response?.instances?.[0]?.originId,
        location: {
          name: response?.instances?.[0]?.destinationName,
          address: response?.instances?.[0]?.destinationAddress,
        },
        originLocation: {
          name: response?.originLocationName,
          address: response?.instances?.[0]?.originAddress,
        },
        destinationLocation: {
          name: response?.instances?.[0]?.destinationName,
          address: response?.instances?.[0]?.destinationAddress,
        },
        tradePartner: {
          name: response?.originCompanyName || '',
          urn: response?.instances?.[0]?.destinationUrn || '',
        },
        originTradePartner: {
          name: response?.originCompanyName || '',
          urn: response?.instances?.[0]?.originUrn || '',
        },
        destinationTradePartner: {
          name: response?.instances?.[0]?.destinationName || '',
          urn: response?.instances?.[0]?.destinationUrn || '',
        },
        destinationId: response?.instances?.[0]?.destinationId,
        destinationTpId: response?.instances?.[0]?.destinationId,
        originId: response?.instances?.[0]?.originId,
        originTpId: response?.instances?.[0]?.originId,
        eventDate: response?.eventDate,
        loggedDate: response?.loggedDate,
        shipmentType: 'Wholechain',
        shipmentStatus: 'Pending',
        ticketId: response?.instances?.[0]?.ticketId,
        timezone: response?.timezone || undefined,
        description: '',
        accountId: '',
        urn: '',
        purchaseOrder: response?.purchaseOrderNumber,
        templateId: '',
        templateName: '',
        productInstances:
          response?.instances?.map(
            (item) => ({
              ...item,
              id: item?.id,
              productId: item?.productId,
              eventId: item?.id,
              containerId: '',
              quantity: item?.quantity,
              shipped: true,
              urn: item?.productUrn,
              lotSerial: item?.lotSerial,
              productName: item?.productName,
              productIdentifierType: item?.productIdentifierType,
              simpleUnitOfMeasurement: item?.unitOfMeasure,
              unitQuantity: item?.unitQuantity,
              unitDescriptor: item?.unitDescriptor,
            }),
            [],
          ) || [],
        containers: response?.containers?.map(
          (c) => ({
            id: c?.containerId,
            containerIdentifier: c?.containerIdentifier,
            urn: c?.urn,
            eventId: c?.shipmentEventId,
            productInstances: c?.instances?.map(
              (item) => ({
                ...item,
                id: item?.id,
                productId: item?.productId,
                eventId: item?.id,
                containerId: '',
                quantity: item?.quantity,
                shipped: true,
                urn: item?.productUrn,
                lotSerial: item?.lotSerial,
                productName: item?.productName,
                productIdentifierType: item?.productIdentifierType,
                simpleUnitOfMeasurement: item?.unitOfMeasure,
                unitQuantity: item?.unitQuantity,
                unitDescriptor: item?.unitDescriptor,
              }),
              [],
            ),
          }),
          [],
        ),
        masterData: [],
        certifications: [],
        transformationId: '',
        readpoint: '',
      };

      setActiveEvent(shipInfo);
      infoModal?.show();
    },
    [infoModal, shipDetails],
  );

  const onRowActionClick = useCallback(
    (actionItemKey: string, actionPayload?: InboundShipmentResponse) => {
      switch (actionItemKey) {
        case 'receive':
          onViewEvent(actionPayload);
          break;

        /*  case 'reject':
          break; */

        case 'preview_shipment':
          onPreviewEvent(actionPayload);
          break;

        default:
          break;
      }
      setAdvancedSettings(false);
      setDataEntryMethod('manual');
      setTemplate(undefined);
    },
    [onPreviewEvent, onViewEvent, setAdvancedSettings, setDataEntryMethod, setTemplate],
  );

  useEffect(() => {
    if (!showFilter) {
      setParams({
        pageNumber: 1,
        pageSize: 10,
        sortBy: '-eventDate',
        status: ['Pending'],
      });
    }
  }, [showFilter]);

  const toolBarRender: ListToolBarProps = useMemo(
    () =>
      showFilter
        ? {
            multipleLine: true,
            filter: (
              <ShipmentToolbar
                t={t}
                originLocationUrn={params.shippedFromTradePartnerUrn}
                purchaseOrderNumber={params.purchaseOrder}
                originTradePartnerUrn={params.shippedToTradePartnerUrn}
                onLocationChange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    shippedFromTradePartnerUrn: value,
                  })
                }
                onTradePartnerChange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    shippedToTradePartnerUrn: value,
                  })
                }
                onPOchange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    purchaseOrder: value,
                  })
                }
              />
            ),
          }
        : {},
    [showFilter, t, params],
  );
  return (
    <>
      <DetailsModal
        key={activeEvent?.id}
        modalProps={infoModal}
        activeEvent={activeEvent}
        loading={!activeEvent}
        hideDocument
      />
      <GTable<OtherSystemShipmentResponse>
        actionRef={actionRef}
        showHeader={false}
        rowKey="shipmentId"
        columns={columns({ t, onRowActionClick })}
        columnsState={{
          persistenceKey: 'pages-products1',
        }}
        loading={isLoading}
        value={data?.results}
        enableRowSelection
        recordCreatorProps={false}
        pagination={{
          defaultPageSize: params.pageSize,
          total: data?.totalItems,
          current: params?.pageNumber,
          showTotal: (total) =>
            total <= 1
              ? t('search_table_pagi_shipment', { total })
              : t('search_table_pagi', { total }),
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: onRowSelectionChange,
          columnWidth: 30,
          preserveSelectedRowKeys: true,
        }}
        onTableChange={onTableChange}
        scroll={{ x: 915 }}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
        options={{
          setting: false,
          reload: false,
        }}
        toolbar={showFilter ? toolBarRender : undefined}
        emptyContainer={
          <NoShipmentsFound t={t} params={params} shipmentType="OtherSystem" isInbound />
        }
      />
    </>
  );
};

export default React.memo(InboundExternalSystemShipments);
