export const setInboundSettingsTabSession = (tabId: string) => {
  sessionStorage.setItem('InboundSettingsTab', tabId);
};

export const getInboundSettingsTabSession = () => {
  if (!sessionStorage.getItem('InboundSettingsTab')) {
    setInboundSettingsTabSession('internal');
  }
  return sessionStorage.getItem('InboundSettingsTab') || 'internal';
};

export const removeInboundSettingsTabSession = () => {
  sessionStorage.removeItem('InboundSettingsTab');
};
